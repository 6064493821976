import React, { ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import { useModal } from '../../hooks/use-modal';
import { strings } from '../../localization/strings';

interface ComponentProps {
  children: ReactNode;
  title?: string;
  type?: string;
  visible?: boolean;
  wide?: boolean;
  onCloseClick?: () => void;
  fullHeight?: boolean;
}

function ModalContent({ type, title, children, visible, onCloseClick, wide, fullHeight }: ComponentProps) {
  const { isVisible, setVisible } = useModal();

  const isModalVisible = (type && isVisible(type)) || visible;

  const onClose = () => {
    if (onCloseClick) {
      onCloseClick();
    } else if (type) {
      setVisible(type, false);
    }
  };

  return (
    <Transition.Root show={isModalVisible}>
      <Dialog
        as="div"
        className="text-dialog fixed inset-0 overflow-y-auto !max-h-full z-[9999]"
        onClose={() => onClose()}
      >
        <div
          className={`flex items-center max-w-full content-center justify-center p-3 md:p-4 text-center ${
            fullHeight && 'h-full items-stretch'
          }`}
        >
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div
            className={classNames(
              'w-full md:w-auto flex align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:align-middle md:mx-auto sm:p-6 !max-h-full',
              fullHeight && '!h-full items-stretch',
              wide && 'md:w-full lg:w-11/12 2xl:max-w-7xl xl:px-10',
            )}
          >
            <div className="block absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => onClose()}
              >
                <span className="sr-only">{strings.button.close}</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="w-full">
              {title && (
                <Dialog.Title as="h1" className="text-xl leading-6 font-medium text-gray-900">
                  {title}
                </Dialog.Title>
              )}
              <div className="mt-2 h-full relative d-block">{children}</div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

ModalContent.defaultProps = {
  title: '',
  visible: false,
  wide: false,
  type: '',
  onCloseClick: () => null,
  fullHeight: false,
};

export default ModalContent;
