import { Month } from '../../entities/month';

const monthFormatter = new Intl.DateTimeFormat('lt', { month: 'long' });
const dateFormatter = new Intl.DateTimeFormat('lt', { day: '2-digit', month: '2-digit', year: 'numeric' });
const durationFormatter = new Intl.RelativeTimeFormat('lt', { style: 'narrow' });
const dateTimeFormatter = new Intl.DateTimeFormat('lt', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
});
const timeFormatter = new Intl.DateTimeFormat('lt', {
  hour: '2-digit',
  minute: '2-digit',
});

export const getLongMonthName = (month: number): string => {
  return monthFormatter.format(new Date(2000, month - 1, 1));
};

export const getLongMonthName2 = (locale = 'lt'): string => {
  const today = new Date();
  const formatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return formatter.format(today);
};

export const fromJsDate = (date: Date): string => {
  return dateFormatter.format(date);
};

export const sortByDateDesc = (dates: { date: Date }[]) => {
  return dates.sort((a, b) => {
    return b.date.getTime() - a.date.getTime();
  });
};

export const isValidSubscriptionDates = (fromDate: Date, toDate: Date) => {
  const now = Date.now();

  if (!fromDate || !toDate) {
    return false;
  }

  return fromDate < toDate && fromDate.getTime() < now && toDate.getTime() > now;
};

export const sortByDateAsc = (dates: { date: Date }[]) => {
  return dates.sort((a, b) => {
    return a.date.getTime() - b.date.getTime();
  });
};

export const fromTimestamp = (timestamp: number | string, hasTime?: boolean): string => {
  if (hasTime) {
    return dateTimeFormatter.format(new Date(timestamp));
  }

  return fromJsDate(new Date(timestamp));
};

export const fromTimestampFromSeconds = (timestamp: number, hasTime?: boolean): string => {
  if (hasTime) {
    return dateTimeFormatter.format(new Date(timestamp * 1000));
  }

  return fromJsDate(new Date(timestamp * 1000));
};

export const getDurationSeconds = (date: number | string): number => {
  return (new Date(date).getTime() - new Date().getTime()) / 1000;
};

export const getDurationFromDateString = (date: number | string): string => {
  if (!date) {
    return '';
  }

  const seconds = getDurationSeconds(date);

  const rangeSeconds = Math.abs(seconds);

  if (rangeSeconds > 3600 * 24 * 30 * 12) {
    return durationFormatter.format(Math.floor(seconds / 31536000), 'year');
  }

  if (rangeSeconds > 3600 * 24 * 30) {
    return durationFormatter.format(Math.floor(seconds / 2592000), 'month');
  }

  if (rangeSeconds > 3600 * 24) {
    return durationFormatter.format(Math.floor(seconds / 86400), 'day');
  }
  if (rangeSeconds > 3600) {
    return durationFormatter.format(Math.floor(seconds / 3600), 'hour');
  }
  if (rangeSeconds > 60) {
    return durationFormatter.format(Math.floor(seconds / 60), 'minute');
  }

  return durationFormatter.format(Math.floor(seconds), 'second');
};

export const createMonths = (months: number[]): Month[] => {
  if (!months) {
    return months;
  }

  return months.map((n) => {
    return {
      id: n,
      title: getLongMonthName(n),
    };
  });
};

export const getMonthsList = (): Month[] => {
  return createMonths(Array.from(Array(12)).map((n, i) => i + 1));
};

export const getHumanDate = (timestamp: number): string => {
  const timeDiff = Date.now() - timestamp;

  if (timeDiff < 15000) {
    return 'ką tik';
  }

  const durations = [
    { name: 'sek.', diff: 60000, value: 1000 },
    { name: 'min.', diff: 3600000, value: 60000 },
    { name: 'val.', diff: Math.min(new Date().getHours(), 5) * 3600 * 1000, value: 3600000 },
  ];

  const duration = durations.find((d) => timeDiff < d.diff);

  if (duration) {
    return `prieš ${Math.round(timeDiff / duration.value)} ${duration.name}`;
  }

  const timeWithDay = [
    { name: '', diff: 0 },
    { name: 'vakar', diff: 86400000 },
    { name: 'užvakar', diff: 86400000 * 2 },
  ];

  for (const time of timeWithDay) {
    if (new Date(timestamp).toDateString() === new Date(Date.now() - time.diff).toDateString()) {
      return `${time.name ? `${time.name}, ` : ''} ${timeFormatter.format(new Date(timestamp))}`;
    }
  }

  return dateTimeFormatter.format(new Date(timestamp));
};

export const getMiliseconds = (date: string) => {
  // return new Date(Date.parse(date)).getTime();
  return Date.parse(date);
};

export const getUnixTimestamp = (date: Date) => {
  return Math.floor(new Date(date).getTime() / 1000);
};
