import React, { useState } from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAuth } from '../../../hooks/use-auth';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import { strings } from '../../../localization/strings';
import { Pagination } from '../../../entities/pagination';
import { SimpleObject } from '../../../entities/simple-object';
import { fillFromParams } from '../../../services/crud/filter';
import * as photosService from '../../../services/api/photos';
import { companyAdminStructure } from '../../../services/api/categories';
import { CompanyAdminStructure } from '../../../entities/company-admin-structure';
import { Photo } from '../../../entities/photo';
import PhotosFilter from '../components/filter';
import FilterPhotos from '../../../entities/filter/photo';
import { Author } from '../../../entities/author';
import PhotoList from '../components/photo-list';
import Heading1 from '../../../components/common/headings/heading1';

function PhotosPageComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const isLocalPhotosPage = location.pathname.includes('local');
  const { token } = useAuth();
  const queryClient = useQueryClient();

  const initialState: FilterPhotos = {
    page: 0,
    sort: '-created_at',
    is_published: [1, 0],
    language_id: 0,
  };

  const types = {
    isNumeric: ['page', 'is_published', 'language_id'],
    isDate: ['valid_to_start', 'valid_to_end'],
    isArray: ['is_published', 'ids', 'author_id'],
  };
  const [params, setParams] = useState<FilterPhotos>(fillFromParams<FilterPhotos>(initialState, searchParams, types));

  const updatePage = (currentPage: number) => {
    setParams({ ...params, page: currentPage });
    setSearchParams({ ...params, page: currentPage } as SimpleObject);
  };

  const {
    data: localPhotos,
    error,
    isLoading: isLocalPhotosLoading,
    isFetching: isLocalPhotosFetching,
  } = useQuery<{ items: Photo[]; pagination: Pagination }, Error>({
    queryKey: ['localPhotos', params],
    queryFn: () => photosService.list(token, params),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
  });

  const { error: categoriesError, data: categories } = useQuery<CompanyAdminStructure>({
    queryKey: ['categories'],
    queryFn: () => companyAdminStructure(token),
    refetchOnWindowFocus: false,
  });

  const { data: authors, error: authorsError } = useQuery<Author[], Error>({
    queryKey: ['photoAuthors'],
    queryFn: () => photosService.authors(token, true),
    refetchOnWindowFocus: false,
  });

  const setFilterData = async (filterData: FilterPhotos) => {
    const q: SimpleObject = {};

    // Cleanup undefined and null values. We don't want them in URLs
    for (const [key, value] of Object.entries(filterData)) {
      if (typeof value !== 'undefined' && value !== null) {
        q[key] = value;
      }
    }

    setParams({ ...filterData, page: 0 });
    setSearchParams({ ...q, page: 0 } as SimpleObject);
    await queryClient.invalidateQueries({ queryKey: ['localPhotos', params] });
  };

  return (
    <div>
      <header className="mb-3">
        <div className="sm:flex sm:space-x-3 justify-between">
          <Heading1>{strings.navigation.photos}</Heading1>
          {isLocalPhotosPage && (
            <div className="mt-3 sm:mt-0">
              <Link
                className="block text-center text-base rounded-lg py-2 px-4 font-medium bg-blue-800 text-white hover:bg-blue-900 no-underline"
                to="upload"
              >
                {strings.album.buttons.addPhotos}
              </Link>
            </div>
          )}
        </div>
      </header>

      <main className="w-full">
        {(error || authorsError) && (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
            <AlertPrimary type="danger" text={error?.message || authorsError?.message} />
          </div>
        )}

        {categoriesError && (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
            <AlertPrimary type="danger" text={(categoriesError as AxiosError).message} />
          </div>
        )}
        {categories && authors && (
          <PhotosFilter
            onUpdate={setFilterData}
            initialState={initialState}
            filterState={params}
            categories={categories}
            authors={authors}
          />
        )}
        <PhotoList
          params={params}
          localPhotos={localPhotos}
          isLocalPhotosLoading={isLocalPhotosLoading}
          isLocalPhotosFetching={isLocalPhotosFetching}
          onUpdatePage={(page) => updatePage(page)}
        />
      </main>
    </div>
  );
}

export default PhotosPageComponent;
