import React, { useCallback } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { TrashIcon, PhotoIcon } from '@heroicons/react/20/solid';

import CommonButtonDefault from '../buttons/default';
import { strings } from '../../../localization/strings';
import UploadedFile from '../../../entities/form/uploaded-file';
import AlertPrimary from '../alerts/alert-primary';

interface ComponentProps {
  onChange: (photos: UploadedFile[]) => void;
  photos: UploadedFile[];
}

function AlbumDropzone({ onChange, photos }: ComponentProps) {
  // Callback for handling dropped files
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      event.preventDefault(); // Prevent form auto-submit
      event.stopPropagation(); // Stop event bubbling
      onChange([
        ...photos,
        ...acceptedFiles.map((f) => {
          return { file: f, preview: URL.createObjectURL(f) };
        }),
      ]);
    },
    [onChange, photos],
  );

  const onRemoveFile = (file: File) => {
    const newFiles = [...photos];

    newFiles.splice(
      newFiles.findIndex((f) => f.file === file),
      1,
    );

    onChange(newFiles);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    useFsAccessApi: true,
    noClick: true,
  });

  return (
    <div>
      <section>
        <div
          {...getRootProps({
            className:
              'dropzone justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 text-center',
          })}
        >
          <input {...getInputProps()} />
          <PhotoIcon className="mx-auto size-12 text-gray-300" />

          <p className="mt-4 text-sm/6 text-gray-600 text-center">{strings.photos.dropzone.selectPhotos.firstRow}</p>
          <CommonButtonDefault sm secondary onClick={open} className="mx-auto mt-3">
            {strings.photos.dropzone.selectPhotos.button}
          </CommonButtonDefault>
        </div>
        {photos.length > 0 && (
          <div className="pt-5">
            <CommonButtonDefault sm danger type="button" onClick={() => onChange([])}>
              {strings.button.clearPhotos}
            </CommonButtonDefault>
          </div>
        )}

        {photos.length > 0 && (
          <aside className="mt-5">
            <ul className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
              {photos.map((file, key) => (
                <li key={`file-${key + 1}`}>
                  {file.error && (
                    <div className="my-5 text-left">
                      <AlertPrimary type="danger" size="xs" title={file.error} />
                    </div>
                  )}
                  <div className="overflow-hidden rounded-lg border border-gray-200 bg-white">
                    <div className="relative w-full">
                      <div className="h-40 !bg-gray-100 flex items-center justify-center">
                        <img
                          alt={file.file.name}
                          src={file.preview}
                          className="object-cover object-center z-1 max-h-full max-w-full"
                        />
                      </div>
                      <CommonButtonDefault
                        secondary
                        className="absolute top-1 right-1 z-10 !px-2.5 z-2"
                        onClick={() => onRemoveFile(file.file)}
                      >
                        <TrashIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-400 hover:text-red-600"
                          aria-hidden="true"
                        />
                      </CommonButtonDefault>
                    </div>
                    <div className="flex flex-1 flex-col space-y-2 p-3">
                      <h3 className="text-xs font-medium break-words">{file.file.name}</h3>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </aside>
        )}
      </section>
    </div>
  );
}
AlbumDropzone.defaultProps = {
  // photosWithError: null,
};

export default AlbumDropzone;
