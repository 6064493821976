import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { PhotoIcon } from '@heroicons/react/20/solid';

import CommonButtonDefault from '../../../components/common/buttons/default';
import Badge from '../../../components/common/badge';
import { strings } from '../../../localization/strings';
import { fromTimestamp, fromTimestampFromSeconds } from '../../../services/formatter/date';
import { Album } from '../../../entities/album';
import { parameters } from '../../../constants/parameters';
import ModalContent from '../../../components/modals/content';
import { ModalKeys } from '../../../context/modal';
import AlbumCreateForm from './album-create-form';
import CommonConfirmationModal from '../../../components/modals/confirmation';

interface ComponentProps {
  item: Album;
  onPublish: (item: Album) => void;
  onDelete: (id: number) => void;
  onDeleteAll: (id: number) => void;
}

export default function AlbumComponent({ item, onPublish, onDelete, onDeleteAll }: ComponentProps) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [selectedAlbum, setSelectedAlbum] = useState<Album | undefined>();
  const [toDelete, setToDelete] = useState<number>();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const showDelete = (id: number) => {
    setConfirmDelete(true);
    setToDelete(id);
  };
  const onDisplayenu = (show: boolean) => {
    setShowMenu(show);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div key={item.id}>
      <div className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white">
        <div className="relative">
          <div className=" flex items-center justify-center !px-0 !py-0 w-full text-center h-40 2xl:h-42 bg-gray-100">
            {item?.preview_photo ? (
              <img
                alt={item.title}
                src={`${parameters.websiteUrl}/preview/${item?.preview_photo}`}
                className="object-cover object-center"
              />
            ) : (
              <div className="h-full flex items-center">
                <PhotoIcon className="text-gray-300 m-auto" width={60} height={60} />
              </div>
            )}
          </div>

          <div className="absolute z-10 top-1 right-1">
            <CommonButtonDefault secondary className="!px-2 !py-1.5" onClick={() => onDisplayenu(!showMenu)}>
              <Cog6ToothIcon width={20} height={20} />
            </CommonButtonDefault>
            {showMenu && (
              <div
                ref={menuRef}
                className="absolute right-1 text-left flex-start text-sm min-w-[130px] flex flex-col space-y-2 bg-white py-2 px-3 z-10 ring-1 ring-inset ring-gray-300 rounded-b-md rounded-ss-md"
              >
                <Link className="text-gray-900 no-underline hover:text-blue-500" to={`upload/${item.id}`}>
                  {strings.navigation.uploadPhotos}
                </Link>
                <Link className="text-gray-900 no-underline hover:text-blue-500" to={`view/${item.id}`}>
                  {strings.navigation.photos}
                </Link>
                <CommonButtonDefault sm menulink onClick={() => setSelectedAlbum(item)}>
                  {strings.button.edit}
                </CommonButtonDefault>
                <CommonButtonDefault sm menulink onClick={() => onPublish(item)}>
                  {strings.button.publish}
                </CommonButtonDefault>
                <CommonButtonDefault className="text-left" sm menulink onClick={() => showDelete(item.id)}>
                  {strings.button.delete}
                </CommonButtonDefault>
              </div>
            )}
          </div>
          {!item.published_at && (
            <span className="absolute bottom-1 left-1 z-10">
              <Badge status="warning" text="Ruošiama" />
            </span>
          )}
        </div>
        <div className="flex flex-1 flex-col space-y-2 p-3">
          <h3 className="text-sm font-medium">
            <Link className="text-gray-900 no-underline hover:text-indigo-600" to={`/albums/view/${item.id}`}>
              <span aria-hidden="true" className="absolute inset-0" />
              {item.title}
            </Link>
          </h3>
          <div className="">
            <div className="text-xs text-gray-500">
              {strings.album.created} {item?.created_at && fromTimestamp(item?.created_at, true)}
              <div>
                {strings.album.published} {item.published_at ? fromTimestampFromSeconds(item.published_at, true) : '-'}
              </div>
            </div>
          </div>
          <div className="text-xs text-gray-500">
            {strings.album.photosCount}: {item.photos_count || 0}
          </div>
        </div>
      </div>
      <ModalContent
        type={ModalKeys.EditItem}
        onCloseClick={() => setSelectedAlbum(undefined)}
        title={strings.album.modal.editTitle}
        visible={!!selectedAlbum}
      >
        <div className="md:min-w-96 pt-5">
          <AlbumCreateForm album={selectedAlbum} onCloseForm={() => setSelectedAlbum(undefined)} />
        </div>
      </ModalContent>

      <CommonConfirmationModal
        open={confirmDelete}
        confirmed={() => onDelete(toDelete || 0)}
        confirmedAll={() => onDeleteAll(toDelete || 0)}
        cancelled={() => setConfirmDelete(false)}
        title={strings.album.modal.delete.title}
        description={strings.album.modal.delete.description}
        buttonConfirm={strings.album.modal.delete.confirm}
        buttonConfirmAll={strings.album.modal.delete.confirmDeleteAll}
      />
    </div>
  );
}
