export const strings = {
  common: {
    yes: 'Taip',
    no: 'Ne',
    searchLabel: 'Ieškoti',
    searchKeywordsLabel: 'Ieškoti tarp raktažodžių',
    button: {
      edit: 'Redaguoti',
      save: 'Išsaugoti',
      exit: 'Išeiti',
      selectAllPhotos: 'Pažymėti visas nuotraukas',
    },
    pagination: {
      previous: 'Praeitas',
      next: 'Kitas',
      showing: 'Rodoma',
      from: 'nuo',
      to: 'iki',
      of: 'iš',
      items: 'įrašų',
    },
    confirmation: {
      deleteCompany: {
        title: 'Ar tikrai norite ištrinti šią įmonę?',
        description:
          'Trinant įmonę kartu bus ištrinti visi įmonės vartotojai ir jie nebegalės prisijungti. Taip pat po ištrynimo nebus galimybės sugrąžinti atgal ištrintos informacijos.',
      },
      deleteFile: {
        title: 'Ar tikrai norite ištrinti šį dokumentą?',
        description: 'Ištrynus jį, nebebus įmanoma sugrąžinti atgal.',
      },
      deleteUser: {
        title: 'Ar tikrai norite ištrinti šį vartotoją?',
        description: 'Ištrynus jį, nebebus įmanoma sugrąžinti atgal.',
      },
    },
  },
  companies: {
    registered: 'Registracijos data',
    demo: 'Demo',
    active: 'Aktyvus',
    name: 'Pavadinimas',
    subscription: 'Prenumerata',
    expires_at: 'Galioja iki',
    news_subscription: 'Naujienų prenumerata',
    press_releases: 'Pranešimai spaudai',
    filter: {
      loginState: 'Prisijungimas',
      serviceState: 'Paslaugų statusas',
      service: 'Aktyvi paslauga',
      subscriptionExpirationDate: 'Prenumerata galioja iki',
      pressReleaseExpirationDate: 'Pranešimai spaudai galioja iki',
      activeFilters: 'filtras|filtrai|filtrų',
      select: 'Filtrai',
    },
  },
  error: {
    fieldIsRequired: 'Laukas yra privalomas',
    companyNamedIsRequired: 'Įmonės pavadinimas privalomas',
    pageNotFound: {
      title: 'Puslapis nerastas',
      description: 'Patikrinkite adresą arba bandykite dar kartą.',
      goHome: 'Grįžti į pradžią',
    },
  },
  form: {
    email: 'El.pašto adresas',
    password: 'Slaptažodis',
    error: {
      emailFormat: 'Įvestas tekstas enatitinka el. pašto formato',
      required: 'Laukas privalomas',
      categoryRequired: 'Pasirinkite temą',
    },
  },
  filter: {
    clearFilter: 'Išvalyti',
  },
  button: {
    login: 'Prisijungti',
    close: 'Uždaryti',
    edit: 'Redaguoti',
    view: 'Peržiūrėti',
    delete: 'Ištrinti',
    pin: 'Pažymėti',
    unpin: 'Atžymėti',
    save: 'Išsaugoti',
    comment: 'Komentuoti',
    cancel: 'Atšaukti',
    removeAll: 'Remove All',
    create: 'Sukurti',
    translate: 'Versti',
    publish: 'Publikuoti',
    download: 'Atsisiųsti',
    downloading: 'Siunčiama..',
    preview: 'Peržiūrėti',
    clearPhotos: 'Pašalinti nuotraukas',
  },
  navigation: {
    returnToMainAdmin: 'Grįžti į seną versiją',
    companies: 'Įmonės',
    photos: 'Nuotraukos',
    uploadPhotos: 'Įkelti nuotraukas',
    lt: 'Lietuvos',
    foreign: 'Užsienio',
    photosInPreparation: 'Ruošiamos nuotraukos',
    photosforeignAgencies: 'Užsienio agentūrų nuotraukos',
    photoAlbums: 'Albumai',
    signOut: 'Atsijungti',
  },
  search: {
    noResults: 'Nerasta jokių įrašų',
  },
  payments: {
    periodicityOptions: {
      monthly: 'Kas mėnesį',
      singleUse: 'Vienkartinis',
      individual: 'Individualus',
    },
    invoiceBy: {
      invoice_email: 'El. paštu',
      invoice_einvoice: 'E. sąskaita faktūra',
    },
  },
  documents: {
    types: {
      contract: 'Sutartis',
      appendix: 'Priedas',
      other: 'Kita',
    },
  },
  photos: {
    filter: {
      state: {
        title: 'Nuotraukų būsena',
        active: 'Publikuotos',
        inactive: 'Ruošiamos',
      },
      category: {
        title: 'Temos',
      },
      subCategory: {
        title: 'Potemė',
      },
      language: 'Pasirinkite kalbą',
      date: 'Nuotraukos įkėlimo data',
      author: 'Ieškoti pagal autorių',
    },
    backToList: 'Grįžti į nuotraukų sąraša',
    header: {
      upload: 'Nuotraukų įkėlimas',
      list: 'Nuotraukos',
      edit: 'Nuotraukų redagavimas',
      translate: 'Nuotraukų vertimas',
      photosList: {
        translate: 'Photos to translate',
        edit: 'Photos to edit',
      },
    },
    list: {
      empty: 'Deja, bet dar nėra įkeltų nuotraukų',
      missingRequiredFields: 'Pasirinktų nuotraukų sąraše yra nuotraukų/-a, be privalomos informacijos.',
    },
    modal: {
      deletePhotos: {
        title: 'Ar tikrai norite ištrinti pasirinktas nuotraukas?',
        text: 'Paspaudus myktuką "Taip", nuotraukos bus negrįžtamai ištrintos.',
      },
    },
    edit: {
      form: {
        published: 'Publikuota:',
        publishPhoto: 'Publikuoti nuotraukas po išsaugojimo',
        title: 'Pavadinimas',
        description: 'Aprašymas',
        keywords: 'Raktažodžiai',
        country: 'Šalis',
        language: 'Kalba',
        author: 'Autorius',
        category: 'Tema',
        subcategory: 'Potemė',
        placeholder: {
          country: 'Pasirinkite šalį',
          author: 'Pasirinkite autorių',
          category: 'Pasirinkite temą',
          subcategory: 'Pasirinkite potemę',
        },
        addCategory: 'Pridėti kategoriją',
        errors: {
          maxLength: 'Maksimalus teksto ilgis - 300 simboliai.',
          oneKeyword: 'Įveskite bent vieną raktažodį',
          noPhoto: 'Deja, bet tokios nuotraukos nėra',
          noSubcategory: 'Ši tema neturi potėmių',
          fillRequired: 'Neužpildėte visų privalomų laukų',
        },
      },
      modal: {
        copyValues: {
          title: 'Ar tikrai norite kopijuoti laukelio reikšmę?',
          text: 'Paspaudus myktuką "Taip", pasirinkto laukelio tekstas bus nukuopijuotas į visus jį atitinkančius laukelius.',
        },
      },
    },
    dropzone: {
      selectPhotos: {
        firstRow: 'Pasirinkite įkeliamas nuotraukas arba nutempkite jas į šitą plotą',
        button: 'Rinktis nuotraukas',
      },
      buttons: {
        continueWithPhotos: 'Tęsti su įkeltomis nuotraukomis',
        saveAndContinue: ' Išsaugoti ir tęsti',
        saveAndExit: ' Išsaugoti ir išeiti',
        exit: ' Išeiti',
      },
    },
  },
  albums: {
    filter: {
      state: {
        title: 'Albumo būsena',
        active: 'Publikuoti',
        inactive: 'Ruošiami',
      },
      category: {
        title: 'Temos',
      },
      subCategory: {
        title: 'Potemė',
      },
      date: 'Albumo sukūrimo data',
    },
  },

  album: {
    created: 'Sukurta:',
    published: 'Publikuota:',
    photosCount: 'Nuotraukos',
    filter: {
      selected: 'Pasirinkta nuotraukų:',
    },
    header: {
      upload: 'Įkelkite nuotraukas',
      title: 'Albumas:',
    },
    modal: {
      title: 'Sukurti Albumą',
      editTitle: 'Redaguoti albumą',
      delete: {
        title: 'Ištrinti albumą?',
        description:
          'Šis albumas turi priskirtų nuotraukų. Pasirinkite ar reikėtų nuotraukas ištrinti kartu su albumu, ar palikti publikuotas tačiau nepriskirtas jokiam albumui',
        confirmDeleteAll: 'Kartu ištrinti nuotraukas',
        confirm: 'Palikti nuotraukas be albumo',
      },
    },
    buttons: {
      addPhotos: 'Įkelti nuotraukas',
    },
    form: {
      label: {
        title: 'Pavadinimas',
      },
      alert: {
        success: 'Albumas sėkmingai sukurtas',
      },
    },
  },
};
