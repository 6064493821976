import tw, { styled } from 'twin.macro';

const StyledInput = styled.input<{ disabled?: boolean; noValidation?: boolean; hasError?: boolean }>`
  ${tw`block w-full rounded-md border-0 py-1.5 pl-3 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`};
  ${(props) => (props.disabled ? tw`bg-gray-100 !text-gray-500` : '')}
  ${(props) => (props.noValidation ? tw`pr-3` : tw`pr-10`)}
  ${(props) =>
    props.hasError
      ? tw`text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500`
      : tw`text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600`}
`;

export default StyledInput;
