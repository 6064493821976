export const createQueryStringFromIds = (selectedPhotos: number[], albumId?: number) => {
  let idsParams = selectedPhotos
    .map((id) => `ids=${encodeURIComponent(id)}`) // Encode each ID
    .join('&'); // Join with &

  if (albumId) {
    idsParams += `&albumId=${albumId}`;
  }

  return idsParams;
};
