import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronRightIcon, HomeIcon, TrashIcon } from '@heroicons/react/20/solid';

import { useAuth } from '../../hooks/use-auth';
import CommonButtonDefault from '../../components/common/buttons/default';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import UserForm from '../../entities/form/user';
import * as usersService from '../../services/api/users';
import * as companiesService from '../../services/api/companies';
import { Company } from '../../entities/company';
import { User } from '../../entities/user';
import ValidationError from '../../entities/validation-error';
import LabelWithError from '../../components/common/form/label-with-error';
import StyledInput from '../../components/common/form/styled-input';
import { strings } from '../../localization/strings';
import CommonConfirmationModal from '../../components/modals/confirmation';
import StyledSwitch from '../../components/common/form/styled-switch';
import Heading1 from '../../components/common/headings/heading1';

interface ComponentProps {
  isEdit?: boolean;
}

function UsersEditPageComponent({ isEdit }: ComponentProps) {
  const { token } = useAuth();
  const { id, companyId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {
    register,
    formState: { errors },
    clearErrors,
    setError,
    control,
    reset,
    setValue,
    handleSubmit,
  } = useForm<UserForm>({
    defaultValues: {
      ips: [],
    },
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'ips' });
  const [company, setCompany] = useState<Company>();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const onDelete = async () => {
    if (id && company) {
      setIsLoading(true);
      await usersService.remove(token, parseInt(id, 10));

      navigate(`/companies/view/${company.id}#users`);
    }
  };

  const onSubmit = async (data: UserForm) => {
    setIsLoading(true);
    clearErrors();

    const formattedData: Partial<User> = {
      ...data,
    };

    try {
      if (isEdit && id) {
        await usersService.update(token, parseInt(id, 10), formattedData);
      } else {
        formattedData.company_id = company?.id;

        await usersService.create(token, formattedData);
      }

      navigate(`/companies/view/${company?.id}?success=1#users`);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);

      const err = e as ValidationError<UserForm>;

      err.errors.forEach((item) => {
        setError(item.key, { message: item.value });
      });
    }
  };

  useEffect(() => {
    const fetchItem = async () => {
      if (id) {
        const item = await usersService.one(token, id);

        setCompany(item.company);

        reset(item);

        setIsLoading(false);
      }
    };

    const fetchCompany = async () => {
      if (companyId) {
        const item = await companiesService.one(token, companyId);

        setCompany(item);
        setIsLoading(false);
      }
    };

    if (isEdit) {
      fetchItem();
    } else {
      fetchCompany();
      setValue('is_active', 1);
    }
  }, [setValue, reset, token, id, companyId, isEdit]);

  return (
    <div>
      {isLoading && <CommonAnimatedLoader />}
      {!isLoading && (
        <>
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              <li>
                <div>
                  <a href="/" className="text-gray-400 hover:text-gray-500">
                    <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">Home</span>
                  </a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <a href="/companies" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    Įmonės
                  </a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <a
                    href={`/companies/view/${company?.id}`}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    {company?.name}
                  </a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <span className="ml-4 text-sm font-medium text-gray-500" aria-current="page">
                    {isEdit ? 'Redaguoti vartotoją' : 'Sukurti naują vartotoją'}
                  </span>
                </div>
              </li>
            </ol>
          </nav>
          <header className="mb-3 mt-3">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <Heading1>{isEdit ? 'Redaguoti vartotoją' : 'Sukurti naują vartotoją'}</Heading1>
            </div>
          </header>
          <main>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="shadow border-b border-gray-200 sm:rounded-lg bg-white"
              >
                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-12 p-4">
                  <div className="sm:col-span-3">
                    <LabelWithError title="Vardas" error={errors.first_name?.message}>
                      <StyledInput
                        type="text"
                        {...register('first_name', { required: true })}
                        hasError={!!errors.first_name}
                      />
                    </LabelWithError>
                  </div>
                  <div className="sm:col-span-3">
                    <LabelWithError title="Pavardė" error={errors.last_name?.message}>
                      <StyledInput
                        type="text"
                        {...register('last_name', { required: true })}
                        hasError={!!errors.last_name}
                      />
                    </LabelWithError>
                  </div>
                  <div className="sm:col-span-3">
                    <LabelWithError title="Prisijungimo vardas" error={errors.username?.message}>
                      <StyledInput
                        type="string"
                        {...register('username', { required: true })}
                        hasError={!!errors.username}
                      />
                    </LabelWithError>
                  </div>
                  <div className="sm:col-span-3">
                    <LabelWithError title="El.pašto adresas" error={errors.email?.message}>
                      <StyledInput
                        type="email"
                        {...register('email', {
                          required: true,
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: strings.form.error.emailFormat,
                          },
                        })}
                        hasError={!!errors.email}
                      />
                    </LabelWithError>
                  </div>
                  <div className="sm:col-span-3">
                    <LabelWithError title="Pareigos" error={errors.position?.message}>
                      <StyledInput type="text" {...register('position')} hasError={!!errors.position} />
                    </LabelWithError>
                  </div>
                  <div className="sm:col-span-3">
                    <LabelWithError title="Kontaktinis telefonas" error={errors.phone?.message}>
                      <StyledInput type="text" {...register('phone')} hasError={!!errors.phone} />
                    </LabelWithError>
                  </div>
                  <div className="sm:col-span-3">
                    <LabelWithError title="Slaptažodis" error={errors.password?.message}>
                      <StyledInput
                        type="password"
                        {...register('password', { required: !isEdit })}
                        hasError={!!errors.password}
                      />
                    </LabelWithError>
                  </div>
                  <div className="sm:col-span-3">
                    <LabelWithError title="Pakartokite slaptažodį" error={errors.password_confirmation?.message}>
                      <StyledInput
                        type="password"
                        {...register('password_confirmation', { required: !isEdit })}
                        hasError={!!errors.password_confirmation}
                      />
                    </LabelWithError>
                  </div>
                </div>

                <div className="border-t border-gray-900/5">
                  <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-12 p-4">
                    <div className="sm:col-span-6">
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Pastabos ir komentarai
                      </label>
                      <div className="mt-1">
                        <textarea
                          {...register('comments')}
                          rows={4}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="relative flex items-start py-1 mt-4">
                        <div className="flex h-6 items-center">
                          <StyledSwitch name="is_active" control={control} />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label htmlFor="active" className="font-medium text-gray-900">
                            Leidžiama prisijungti
                          </label>
                        </div>
                      </div>
                      <div className="relative flex items-start py-1">
                        <div className="flex h-6 items-center">
                          <StyledSwitch name="is_group" control={control} />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label htmlFor="is_group" className="font-medium text-gray-900">
                            Leidžiama prisijungti iš kelių įrenginių vienu metu (Grupinis)
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                        IP adresų rėžiai iš kurių galima prisijungti
                      </label>
                      {fields.map((item, index) => (
                        <div key={item.id} className="mb-4">
                          <div className="flex -space-x-px">
                            <div className="w-1/2 min-w-0 flex-1">
                              <input
                                type="text"
                                {...register(`ips.${index}.start`)}
                                defaultValue={item.start}
                                className="relative block w-full rounded-none rounded-l-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="1.0.0.0"
                              />
                            </div>
                            <div className="min-w-0 flex-1">
                              <input
                                type="text"
                                {...register(`ips.${index}.end`)}
                                defaultValue={item.end}
                                className="relative block w-full rounded-none border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="255.0.0.0"
                              />
                            </div>
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              <TrashIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      ))}

                      <CommonButtonDefault sm onClick={() => append({ start: '', end: '' })} type="button" secondary>
                        Pridėti IP rėžį
                      </CommonButtonDefault>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 rounded-b-lg flex justify-end space-x-4">
                  {isEdit && (
                    <CommonButtonDefault onClick={() => setConfirmDelete(true)} type="button" danger>
                      {strings.button.delete}
                    </CommonButtonDefault>
                  )}
                  <CommonButtonDefault
                    onClick={() => navigate(`/companies/view/${company?.id}#users`)}
                    type="button"
                    secondary
                  >
                    {strings.button.cancel}
                  </CommonButtonDefault>
                  <CommonButtonDefault type="submit" primary>
                    {strings.button.save}
                  </CommonButtonDefault>
                </div>
              </form>
            </div>
            <CommonConfirmationModal
              open={confirmDelete}
              confirmed={() => onDelete()}
              cancelled={() => setConfirmDelete(false)}
              title={strings.common.confirmation.deleteUser.title}
              description={strings.common.confirmation.deleteUser.description}
              buttonConfirm={strings.common.yes}
              buttonCancel={strings.common.no}
            />
          </main>
        </>
      )}
    </div>
  );
}

UsersEditPageComponent.defaultProps = {
  isEdit: false,
};

export default UsersEditPageComponent;
