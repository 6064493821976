import React, { useState } from 'react';
import { Controller, FieldError, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import Select from 'react-select';
import classNames from 'classnames';
import { Switch } from '@headlessui/react';
import { useParams } from 'react-router-dom';

import { Author } from '../../../entities/author';
import { useAuth } from '../../../hooks/use-auth';
import { Language } from '../../../entities/company-admin-structure';
import CommonButtonDefault from '../../../components/common/buttons/default';
import { strings } from '../../../localization/strings';
import StyledLabel from '../../../components/common/form/styled-label';
import { Country } from '../../../entities/form/country';
import StyledInput from '../../../components/common/form/styled-input';
import LabelWithError from '../../../components/common/form/label-with-error';
import StyledTextarea from '../../../components/common/form/styled-textarea';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import { AlbumUploadFieldValues } from '../../../entities/form/album-upload-form';
import { updatedAuthors } from '../../../services/formatter/authors';
import { countries } from '../../../constants/vafiables';
import { Category } from '../../../entities/category';
import AlbumDropzone from '../../../components/common/form/dropzone-album';
import { upload } from '../../../services/api/photos';
import CommonAnimatedLoader from '../../../components/common/animated/loader';
import { PhotoEdit } from '../../../entities/photo';
import { getLongMonthName2 } from '../../../services/formatter/date';
import UploadedFile from '../../../entities/form/uploaded-file';

interface ComponentProps {
  authors: Author[];
  languages: Language[];
}

export default function UploadPhotosForm({ authors, languages }: ComponentProps) {
  const { user, token } = useAuth();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [publishPhoto, setPublishPhoto] = useState<number>(1);
  // const [photosErrors, setPhotosErrors] = useState<SimpleObject[]>();
  const [success, setSuccess] = useState<boolean>(false);
  const [commonError, setCommonError] = useState<string>();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<AlbumUploadFieldValues>({
    defaultValues: {
      categories: [{ id: null, parent_id: null }],
      lang: languages[0].id,
      country: 1,
      author_id: user?.id,
      title: `ELTA / ${user?.name}`,
      content: `Vilnius, ${getLongMonthName2()} (ELTA).`,
      photos: [],
    },
  });

  const {
    fields: categoriesArray,
    remove,
    append,
  } = useFieldArray({
    control,
    name: 'categories',
    keyName: 'categories_id',
  });

  const fileUpload = async (
    file: UploadedFile,
    photoData: PhotoEdit,
  ): Promise<{ status: boolean; id?: number; file: File; preview: string; error?: string }> => {
    try {
      setIsLoading(true);
      let albumId;

      if (id) {
        albumId = Number(id);
      }

      const uploaded = await upload(token, file.file, albumId, photoData);

      setIsLoading(false);

      return {
        status: true,
        id: uploaded.id,
        file: file.file,
        preview: '',
      };
    } catch (e) {
      setIsLoading(false);

      return {
        status: false,
        error: (e as Error).message,
        file: file.file,
        preview: file.preview,
      };
    }
  };

  const onSubmit: SubmitHandler<AlbumUploadFieldValues> = async (data) => {
    try {
      setIsLoading(true);

      const updatedCategories = [];
      const updatedSubCategories = [];

      if (data.categories) {
        for (const categoryRow of data.categories) {
          if (categoryRow.id) {
            updatedSubCategories.push(categoryRow.id);
          }
          if (categoryRow.parent_id) {
            updatedCategories.push(categoryRow.parent_id);
          }
        }
      }

      const updatedItem = {
        lang: data.lang,
        country: data.country,
        content: data.content,
        title: data.title,
        author_id: data.author_id,
        keywords: data.keywords,
        category: updatedCategories,
        subcategory: updatedSubCategories,
        is_published: publishPhoto,
      };

      if (data.photos && data.photos?.length > 0) {
        const results = await Promise.all(
          data.photos.map(async (f) => {
            return fileUpload(f, updatedItem);
          }),
        );

        // console.log('results', results);

        const hasErrors = results.reduce((e, file) => e || !file.status, false);
        const filesWithError = results.filter((r) => !r.status);

        if (!hasErrors) {
          setSuccess(true);
        }

        if (filesWithError) {
          // setPhotosErrors(filesWithError);
          setValue('photos', filesWithError);
        } else {
          // reset photos after upload
          setValue('photos', []);
        }
      }
      // setPhotosErrors(undefined);

      setIsLoading(false);
    } catch (e) {
      if (e instanceof Error) {
        setIsLoading(false);
        setCommonError(e.message);
      }
    }
  };

  const getErrorText = (err?: FieldError, errorType?: string) => {
    if (err) {
      if (errorType === 'required') {
        return strings.photos.edit.form.errors.oneKeyword;
      }

      if (errorType === 'maxLength') {
        return strings.photos.edit.form.errors.maxLength;
      }
    }

    return undefined;
  };

  const findSubcategories = (index: number) => {
    const { categories } = languages[watch(`lang`)];

    const items = categories.find((c) => c.id === watch(`categories.${index}.parent_id`));

    return items?.sub_category || [];
  };

  return (
    <div className="pb-[100px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mx-auto max-w-7xl lg:mx-0">
          <div className="bg-white p-4 shadow sm:rounded-lg ring-1 ring-gray-900/5 xl:col-span-2">
            <section aria-labelledby="cart-heading">
              <ul className="divide-y divide-gray-200">
                <li className="sm:flex py-4 text-sm">
                  <div className="sm:ml-5 flex flex-col space-y-4 w-full">
                    <div className="flex items-center space-x-2">
                      <div className="w-full">
                        <LabelWithError
                          title={strings.photos.edit.form.title}
                          error={errors?.title && strings.form.error.required}
                          hasStar
                        >
                          <StyledInput
                            hasError={!!errors?.title}
                            {...register('title', {
                              required: true,
                            })}
                          />
                        </LabelWithError>
                      </div>
                    </div>

                    <div className="flex items-center space-x-2">
                      <div className="w-full">
                        <LabelWithError
                          title={strings.photos.edit.form.description}
                          error={errors?.content && strings.form.error.required}
                        >
                          <StyledTextarea {...register(`content`)} />
                        </LabelWithError>
                      </div>
                    </div>

                    <div className="flex items-center space-x-2">
                      <div className="w-full">
                        <LabelWithError
                          title={strings.photos.edit.form.keywords}
                          error={getErrorText(errors?.keywords, errors?.keywords?.type)}
                          hasStar
                        >
                          <StyledTextarea
                            hasError={!!errors?.keywords}
                            {...register('keywords', {
                              required: true,
                              maxLength: 300,
                            })}
                          />
                        </LabelWithError>
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 space-y-4 sm:space-y-0 sm:space-x-3">
                      <div>
                        <StyledLabel>{strings.photos.edit.form.country}</StyledLabel>

                        <Controller
                          control={control}
                          name={`country` as const}
                          rules={{ required: 'Pasirinkite šalį' }}
                          render={({ field }) => (
                            <Select
                              className="w-50"
                              options={countries}
                              getOptionValue={(option: Country) => `${option.id}`}
                              getOptionLabel={(option: Country) => `${option.name}`}
                              placeholder={strings.photos.edit.form.placeholder.country}
                              value={countries.find((opt) => opt.id === field.value)}
                              onChange={(e) => {
                                field.onChange(e?.id);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <StyledLabel>{strings.photos.edit.form.language}</StyledLabel>
                        <Controller
                          control={control}
                          name="lang"
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              className="w-50"
                              options={languages}
                              getOptionValue={(option: Language) => `${option.id}`}
                              getOptionLabel={(option: Language) => `${option.name}`}
                              placeholder={strings.photos.edit.form.placeholder.country}
                              value={languages.find((opt) => opt.id === field.value)}
                              onChange={(e) => {
                                field.onChange(e?.id);
                                setValue('categories', [{ id: null, parent_id: null }]);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <StyledLabel>{strings.photos.edit.form.author}</StyledLabel>
                        {authors && (
                          <Controller
                            control={control}
                            name={`author_id` as const}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                className="w-50"
                                options={updatedAuthors(authors, user)}
                                getOptionValue={(option: Author) => `${option.id}`}
                                getOptionLabel={(option: Author) => `${option.first_name} ${option.last_name || ''}`}
                                placeholder={strings.photos.edit.form.placeholder.author}
                                value={updatedAuthors(authors, user).find((opt) => opt.id === field.value)}
                                onChange={(e) => {
                                  field.onChange(e?.id);
                                }}
                              />
                            )}
                          />
                        )}
                      </div>
                    </div>

                    <div>
                      {categoriesArray &&
                        categoriesArray.map((item, index) => (
                          <div
                            key={item.id}
                            className="sm:grid sm:grid-cols-5 pb-5 border-b border-b-gray-200 mb-4 space-y-4 sm:space-y-0 sm:space-x-3 items-center"
                          >
                            <div className="col-span-2">
                              <StyledLabel>
                                {strings.photos.edit.form.category}
                                <span className="text-red-700">*</span>
                              </StyledLabel>
                              <Controller
                                control={control}
                                name={`categories.${index}.parent_id` as const}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <Select
                                    id={`categories.${index}.parent_id`}
                                    className="w-50"
                                    styles={{
                                      menu: (base) => ({ ...base, zIndex: 9999 }),
                                      control: (proprovided) => ({
                                        ...proprovided,
                                        borderColor: errors?.categories?.[index]?.parent_id && '#fca5a5',
                                      }),
                                    }}
                                    options={languages[watch(`lang`)].categories || []}
                                    getOptionValue={(option: Category) => `${option.id}`}
                                    getOptionLabel={(option: Category) => `${option.name}`}
                                    placeholder={strings.photos.edit.form.placeholder.category}
                                    value={languages[watch(`lang`)].categories.find((opt) => opt.id === field.value)}
                                    onChange={(e) => {
                                      field.onChange(e?.id);
                                      setValue(`categories.${index}.id` as const, null);
                                    }}
                                  />
                                )}
                              />

                              {errors && errors.categories?.[index]?.parent_id && (
                                <p className="mt-2 text-sm text-red-600" id="email-error">
                                  {strings.form.error.categoryRequired}
                                </p>
                              )}
                            </div>
                            <div className="col-span-2">
                              <StyledLabel>{strings.photos.edit.form.subcategory}</StyledLabel>
                              {findSubcategories(index).length > 0 && (
                                <Controller
                                  key={`${watch(`categories.${index}.id`)}`}
                                  control={control}
                                  name={`categories.${index}.id` as const}
                                  render={({ field }) => (
                                    <Select
                                      id={`categories.${index}.id`}
                                      isClearable
                                      className="w-50"
                                      styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                                      options={findSubcategories(index)}
                                      getOptionValue={(option: Category) => `${option.id}`}
                                      getOptionLabel={(option: Category) => `${option.name}`}
                                      placeholder={strings.photos.edit.form.placeholder.subcategory}
                                      defaultValue={findSubcategories(index).find((opt) => opt.id === field.value)}
                                      onChange={(e) => {
                                        field.onChange(e?.id);
                                      }}
                                    />
                                  )}
                                />
                              )}

                              {findSubcategories(index).length === 0 && (
                                <div>{strings.photos.edit.form.errors.noSubcategory}</div>
                              )}
                            </div>
                            {categoriesArray.length > 1 && (
                              <div className="pt-5">
                                <CommonButtonDefault sm danger type="button" onClick={() => remove(index)}>
                                  {strings.button.delete}
                                </CommonButtonDefault>
                              </div>
                            )}
                          </div>
                        ))}
                      <div className="mt-4 flex items-center space-x-3">
                        <CommonButtonDefault
                          secondary
                          sm
                          type="button"
                          onClick={() =>
                            append({
                              id: null, // stands for subcategory id
                              parent_id: null, // stands for category id
                            })
                          }
                        >
                          {strings.photos.edit.form.addCategory}
                        </CommonButtonDefault>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </section>

            {success && (watch('photos') ?? []).length < 1 && (
              <div className="mb-3">
                <AlertPrimary type="success" size="xs" text="Nuotraukos sėkmingai įkeltos" />
              </div>
            )}
            {isLoading && <CommonAnimatedLoader />}
            {commonError && <AlertPrimary type="danger" size="xs" title={commonError} />}

            <Controller
              name="photos"
              control={control}
              render={({ field }) => {
                const { onChange } = field;

                return <AlbumDropzone onChange={onChange} photos={field.value || []} />;
              }}
            />
          </div>
          <div>
            <div className="mt-5 bg-white p-4 shadow ring-1 ring-gray-900/5 bottom-0 right-0 fixed z-[999] w-full sm:flex justify-end sm:space-x-3 space-y-4 sm:space-y-0">
              <div className="relative flex items-start py-1 mt-4">
                <div className="flex h-6 items-center">
                  <Switch
                    checked={publishPhoto === 1}
                    onChange={() => {
                      setPublishPhoto(publishPhoto === 1 ? 0 : 1);
                    }}
                    className={classNames(
                      publishPhoto === 1 ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        publishPhoto ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                      )}
                    />
                  </Switch>
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor="is_published" className="font-medium text-gray-900">
                    {strings.photos.edit.form.publishPhoto}
                  </label>
                </div>
              </div>

              <CommonButtonDefault
                disabled={(watch('photos') ?? []).length < 1 || isLoading}
                className="w-full sm:w-auto"
                type="submit"
                primary
              >
                {strings.common.button.save}
              </CommonButtonDefault>
              <CommonButtonDefault secondary className="w-full sm:w-auto">
                {strings.common.button.exit}
              </CommonButtonDefault>
            </div>
          </div>
        </div>
        {/* Error Messages */}
        {Object.keys(errors).length > 0 && (
          <div className="mt-4">
            <AlertPrimary type="danger" text={strings.photos.edit.form.errors.fillRequired} />
          </div>
        )}
      </form>
    </div>
  );
}
