import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { TrashIcon } from '@heroicons/react/20/solid';
import Select from 'react-select';

import CommonButtonDefault from '../buttons/default';
import { uploadFile } from '../../../services/api/files';
import { useCompany } from '../../../hooks/use-company';
import { useAuth } from '../../../hooks/use-auth';
import { SimpleObject } from '../../../entities/simple-object';
import AlertPrimary from '../alerts/alert-primary';

interface UploadedDocument {
  file: File;
  type: string;
}

const documentTypesOptions = [
  { value: 'contract', label: 'Sutartis' },
  { value: 'appendix', label: 'Priedas' },
  { value: 'other', label: 'Kita' },
];

function MyDropzone() {
  const [files, setFiles] = useState<UploadedDocument[]>([]);
  const { companyDetails, setCompany } = useCompany();
  const { token } = useAuth();
  const [errors, setErrors] = useState<SimpleObject>();
  const [success, setSuccess] = useState<boolean>(false);

  // Callback for handling dropped files
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFiles([
        ...files,
        ...acceptedFiles.map((f) => {
          return { file: f, type: documentTypesOptions[0].value };
        }),
      ]);
    },
    [files],
  );

  const onRemoveFile = (file: File) => {
    const newFiles = [...files];

    newFiles.splice(
      newFiles.findIndex((f) => f.file === file),
      1,
    );

    setFiles(newFiles);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    useFsAccessApi: true,
    noClick: true,
  });

  const fileUpload = async (file: File, type: string): Promise<{ status: boolean; message: string }> => {
    try {
      const uploaded = await uploadFile(token, companyDetails.id, file, type);

      if (!companyDetails.documents) {
        companyDetails.documents = [];
      }

      companyDetails.documents?.push(uploaded);

      setCompany({ ...companyDetails, documents: companyDetails.documents });

      return {
        status: true,
        message: '',
      };
    } catch (e) {
      return {
        status: false,
        message: (e as Error).message,
      };
    }
  };

  const onUpdateFileType = (file: UploadedDocument, type?: string) => {
    if (type) {
      const index = files.indexOf(file);

      files[index].type = type;

      setFiles([...files]);
    }
  };

  async function onSubmitFiles() {
    try {
      if (files.length > 0) {
        const results = await Promise.all(
          files.map(async (f) => {
            return fileUpload(f.file, f.type);
          }),
        );

        const hasErrors = results.reduce((e, file) => e || !file.status, false);
        const filesWithError = results.filter((r) => !r.status);

        if (filesWithError) {
          setErrors(filesWithError);
        }

        if (!hasErrors) {
          const newFiles = [...files];

          files.forEach((f) => {
            newFiles.splice(newFiles.indexOf(f), 1);
          });
          setFiles(newFiles);
          setSuccess(true);
        }
      }
    } catch (e) {
      setErrors([(e as Error).message]);
    }
  }

  return (
    <div className="p-3">
      {success && (
        <div className="mb-3">
          <AlertPrimary type="success" size="xs" text="Failai sėkmingai įkelti" />
        </div>
      )}
      {errors &&
        errors?.length > 0 &&
        errors.map((error: SimpleObject, index: number) => (
          <div key={`alert-${index + 1}`} className="mb-5 text-left">
            <AlertPrimary type="danger" size="xs" title={error.message} />
          </div>
        ))}
      <section className="container">
        <div {...getRootProps({ className: 'dropzone p-3 border border-gray-100 text-center' })}>
          <input {...getInputProps()} />

          <p>Nutempkite failus čia arba pasirinkite</p>
          <button
            className="rounded bg-white px-2 py-1 mt-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            type="button"
            onClick={open}
          >
            per failų naršyklę
          </button>
        </div>
        {files.length > 0 && (
          <aside className="mt-5">
            <ul>
              {files.map((file, key) => (
                <li className="grid grid-cols-5 gap-2 items-center mb-4" key={`file-${key + 1}`}>
                  <div className="col-span-2">
                    <Select
                      className="w-50"
                      options={documentTypesOptions}
                      placeholder="Pasirinkite dokumento tipą"
                      value={documentTypesOptions.find((opt) => opt.value === file.type)}
                      onChange={(selected) => onUpdateFileType(file, selected?.value)}
                    />
                  </div>
                  <div className="col-span-3">
                    <div className="flex space-x-2">
                      <div className="break-all  text-xs">{file.file.name}</div>

                      <CommonButtonDefault xs link onClick={() => onRemoveFile(file.file)}>
                        <TrashIcon
                          className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 hover:text-red-600"
                          aria-hidden="true"
                        />
                      </CommonButtonDefault>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </aside>
        )}
      </section>
      {files.length > 0 && (
        <div className="mt-5 flex justify-end">
          <CommonButtonDefault primary disabled={files.length < 1} onClick={() => onSubmitFiles()}>
            Išsaugoti įkeltus failus
          </CommonButtonDefault>
        </div>
      )}
    </div>
  );
}

export default MyDropzone;
