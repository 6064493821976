import React, { useState } from 'react';
import { Controller, FieldError, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Select from 'react-select';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Switch } from '@headlessui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Photo, PhotoEdit } from '../../../entities/photo';
import { Author } from '../../../entities/author';
import { useAuth } from '../../../hooks/use-auth';
import { Language } from '../../../entities/company-admin-structure';
import * as photosService from '../../../services/api/photos';
import { EditPhotoFieldValues } from '../../../entities/form/edit-photo-form';
import CommonButtonDefault from '../../../components/common/buttons/default';
import { strings } from '../../../localization/strings';
import CategoriesFields from './edit-form-categories';
import StyledLabel from '../../../components/common/form/styled-label';
import { Country } from '../../../entities/form/country';
import StyledInput from '../../../components/common/form/styled-input';
import LabelWithError from '../../../components/common/form/label-with-error';
import { parameters } from '../../../constants/parameters';
import StyledTextarea from '../../../components/common/form/styled-textarea';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import { fromTimestampFromSeconds } from '../../../services/formatter/date';
import CategoriesTranslatedFields from './edit-form-categories-translated';
import { countries } from '../../../constants/vafiables';

interface ComponentProps {
  items: Photo[];
  authors: Author[];
  languages: Language[];
  isTranslate?: boolean;
}

type AllowedFields =
  | 'title'
  | 'content'
  | 'content_translated'
  | 'title_translated'
  | 'keywords_translated'
  | 'keywords';

type FieldNameType = `photoRows.${number}.${AllowedFields}`;

export default function EditPhotoForm({ items, authors, languages, isTranslate }: ComponentProps) {
  const { token, user } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const albumId = searchParams.get('albumId');
  const allowPublish = searchParams.get('allowPublish');

  const [publishPhoto, setPublishPhoto] = useState<number>(allowPublish ? 1 : 0);
  const [navigateToList, setNavigateToList] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const mutation = useMutation({
    mutationFn: async (data: PhotoEdit[]) => {
      try {
        await photosService.update(token, data);
      } catch (e) {
        setError((e as Error).message);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['localPhotos'],
      });
      if (navigateToList) {
        if (albumId) {
          navigate({
            pathname: `/albums/view/${albumId}`,
          });
        } else {
          navigate({
            pathname: '/photos/local',
          });
        }
      }
    },
  });

  const mutationTranslation = useMutation({
    mutationFn: async (data: PhotoEdit[]) => {
      try {
        await photosService.translate(token, data);
      } catch (e) {
        setError((e as Error).message);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['localPhotos'] });
      navigate({
        pathname: '/photos/local',
      });
    },
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
    clearErrors,
  } = useForm<EditPhotoFieldValues>({
    defaultValues: {
      photoRows: items,
    },
  });

  const { fields: fieldsPhotos } = useFieldArray({
    name: 'photoRows', // Matches the `photoRow` in `FormValues`
    control,
    keyName: 'fields_photos_id',
  });

  const onSubmit: SubmitHandler<EditPhotoFieldValues> = async (data) => {
    const updatedData: PhotoEdit[] = [];

    for (const item of data.photoRows) {
      const categoriesArray = [];
      const subCategories = [];

      if (item.categories) {
        for (const categoryRow of item.categories) {
          if (categoryRow.id) {
            subCategories.push(categoryRow.id);
          }
          if (categoryRow.parent_id) {
            categoriesArray.push(categoryRow.parent_id);
          }
        }
      }

      if (item.categories_translated) {
        for (const categoryRow of item.categories_translated) {
          if (categoryRow.id) {
            subCategories.push(categoryRow.id);
          }
          if (categoryRow.parent_id) {
            categoriesArray.push(categoryRow.parent_id);
          }
        }
      }

      const updatedItem = {
        lang: item.lang,
        country: item.country,
        content: isTranslate ? item.content_translated : item.content,
        title: isTranslate ? item.title_translated : item.title,
        author_id: item.author_id,
        keywords: isTranslate ? item.keywords_translated : item.keywords,
        category: categoriesArray,
        subcategory: subCategories,
        is_published: allowPublish || isTranslate ? publishPhoto : item.is_published || 0,
        album_id: item.album_id,
      };

      updatedData.push(
        isTranslate
          ? { ...updatedItem, original_id: item.id }
          : { ...updatedItem, original_id: item.original_id, id: item.id },
      );
    }

    if (isTranslate) {
      mutationTranslation.mutate(updatedData);
    } else {
      mutation.mutate(updatedData);
    }
  };

  const findCategories = (index: number) => {
    const { categories } = languages[watch(`photoRows.${index}.lang`)];

    return categories;
  };

  const updatedAuthors = (authorId?: number, authorName?: string, authorLastName?: string) => {
    let authorsUpdated = authors;

    if (authorId) {
      if (!authorsUpdated.find((obj) => obj.id === authorId)) {
        authorsUpdated = [
          {
            id: authorId,
            first_name: `${authorName} ${authorLastName}`,
          },
          ...authorsUpdated,
        ];
      }
    }

    if (user && !authorsUpdated.find((obj) => obj.id === user.id)) {
      authorsUpdated = [
        {
          id: user.id,
          first_name: user.name,
        },
        ...authorsUpdated,
      ];
    }

    return authorsUpdated;
  };

  const copyToAll = (fieldName: string, fieldRowName: FieldNameType, itemIndex: number) => {
    const fieldValue = getValues(fieldRowName);

    fieldsPhotos.forEach((field, index) => {
      if (index > itemIndex || itemIndex === 0) {
        setValue(`photoRows.${index}.${fieldName}` as FieldNameType, fieldValue);
      }
    });

    if (errors?.photoRows) {
      for (const [key, value] of Object.entries(errors?.photoRows)) {
        if (value) {
          for (const [key1] of Object.entries(value)) {
            if (key1 === fieldName) {
              clearErrors(`photoRows.${Number(key)}.${fieldName}` as FieldNameType);
            }
          }
        }
      }
    }
  };

  const getErrorText = (err?: FieldError, errorType?: string) => {
    if (err) {
      if (errorType === 'required') {
        return strings.photos.edit.form.errors.oneKeyword;
      }

      if (errorType === 'maxLength') {
        return strings.photos.edit.form.errors.maxLength;
      }
    }

    return undefined;
  };

  const photoRows = !isTranslate ? fieldsPhotos : fieldsPhotos;

  return (
    <div className="mb-28">
      {error && (
        <div className="max-w-7xl mx-auto my-5">
          <AlertPrimary type="danger" text={error} />
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mx-auto grid grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none xl:grid-cols-3">
          <div className="bg-white p-4 shadow sm:rounded-lg ring-1 ring-gray-900/5 xl:col-span-2">
            <section aria-labelledby="cart-heading">
              <h2 id="cart-heading" className="sr-only">
                {!isTranslate ? strings.photos.header.photosList.edit : strings.photos.header.photosList.translate}
              </h2>

              <ul className="divide-y divide-gray-200">
                {photoRows &&
                  photoRows.map((item, index) => (
                    <li key={item.id} className="sm:flex py-4 text-sm">
                      {item.preview_photo && (
                        <div className="mx-auto sm:mx-0 mb-4 size-24 flex-none rounded-lg border border-gray-200 sm:size-32 overflow-hidden flex items-center justify-center bg-gray-100">
                          <img
                            alt={item.title}
                            src={`${parameters.websiteUrl}/preview/${item?.preview_photo}`}
                            className="max-h-full max-w-full"
                          />
                        </div>
                      )}
                      <div className="sm:ml-5 flex flex-col space-y-4 w-full">
                        <div>
                          {strings.photos.edit.form.published}{' '}
                          {(item.published_at && fromTimestampFromSeconds(item.published_at, true)) || '-'}
                        </div>

                        <div className="flex items-center space-x-2">
                          <div className="w-full">
                            <LabelWithError
                              title={strings.photos.edit.form.title}
                              error={errors?.photoRows?.[index]?.title && strings.form.error.required}
                              hasStar
                            >
                              <StyledInput
                                disabled={isTranslate}
                                key={item.id}
                                hasError={!!errors?.photoRows?.[index]?.title}
                                {...register(`photoRows.${index}.title` as const, {
                                  required: !isTranslate,
                                })}
                              />
                            </LabelWithError>
                          </div>
                          {fieldsPhotos.length > 1 && !isTranslate && (
                            <CommonButtonDefault
                              type="button"
                              xs
                              transparent
                              className="!px-0 mt-5"
                              onClick={() => copyToAll('title', `photoRows.${index}.title`, index)}
                            >
                              <DocumentDuplicateIcon width={18} height={18} />
                            </CommonButtonDefault>
                          )}
                        </div>
                        {isTranslate && (
                          <div className="flex items-center space-x-2">
                            <div className="w-full">
                              <LabelWithError
                                hasStar
                                title={strings.photos.edit.form.title}
                                error={errors?.photoRows?.[index]?.title_translated && strings.form.error.required}
                              >
                                <StyledInput
                                  key={item.id}
                                  hasError={!!errors?.photoRows?.[index]?.title_translated}
                                  {...register(`photoRows.${index}.title_translated` as const, {
                                    required: true,
                                  })}
                                />
                              </LabelWithError>
                            </div>
                            {fieldsPhotos.length > 1 && (
                              <CommonButtonDefault
                                type="button"
                                xs
                                transparent
                                className="!px-0 mt-5"
                                onClick={() =>
                                  copyToAll('title_translated', `photoRows.${index}.title_translated`, index)
                                }
                              >
                                <DocumentDuplicateIcon width={18} height={18} />
                              </CommonButtonDefault>
                            )}
                          </div>
                        )}

                        <div className="flex items-center space-x-2">
                          <div className="w-full">
                            <LabelWithError
                              title={strings.photos.edit.form.description}
                              error={errors?.photoRows?.[index]?.content && strings.form.error.required}
                            >
                              <StyledTextarea
                                disabled={isTranslate}
                                {...register(`photoRows.${index}.content` as const)}
                              />
                            </LabelWithError>
                          </div>
                          {fieldsPhotos.length > 1 && !isTranslate && (
                            <CommonButtonDefault
                              className="!px-0 mt-5"
                              type="button"
                              xs
                              transparent
                              onClick={() => copyToAll('content', `photoRows.${index}.content`, index)}
                            >
                              <DocumentDuplicateIcon width={18} height={18} />
                            </CommonButtonDefault>
                          )}
                        </div>
                        {isTranslate && (
                          <div className="flex items-center space-x-2">
                            <div className="w-full">
                              <LabelWithError
                                title={strings.photos.edit.form.description}
                                error={errors?.photoRows?.[index]?.content_translated && strings.form.error.required}
                              >
                                <StyledTextarea {...register(`photoRows.${index}.content_translated` as const)} />
                              </LabelWithError>
                            </div>
                            {fieldsPhotos.length > 1 && (
                              <CommonButtonDefault
                                className="!px-0 mt-5"
                                type="button"
                                xs
                                transparent
                                onClick={() =>
                                  copyToAll('content_translated', `photoRows.${index}.content_translated`, index)
                                }
                              >
                                <DocumentDuplicateIcon width={18} height={18} />
                              </CommonButtonDefault>
                            )}
                          </div>
                        )}

                        <div className="flex items-center space-x-2">
                          <div className="w-full">
                            <LabelWithError
                              title={strings.photos.edit.form.keywords}
                              error={getErrorText(
                                errors?.photoRows?.[index]?.keywords,
                                errors?.photoRows?.[index]?.keywords?.type,
                              )}
                              hasStar
                            >
                              <StyledTextarea
                                hasError={!!errors?.photoRows?.[index]?.keywords}
                                disabled={isTranslate}
                                {...register(`photoRows.${index}.keywords` as const, {
                                  required: !isTranslate,
                                  maxLength: 300,
                                })}
                              />
                            </LabelWithError>
                          </div>
                          {fieldsPhotos.length > 1 && !isTranslate && (
                            <CommonButtonDefault
                              className="!px-0 mt-5"
                              type="button"
                              xs
                              transparent
                              onClick={() => copyToAll('keywords', `photoRows.${index}.keywords`, index)}
                            >
                              <DocumentDuplicateIcon width={18} height={18} />
                            </CommonButtonDefault>
                          )}
                        </div>
                        {isTranslate && (
                          <div className="flex items-center space-x-2">
                            <div className="w-full">
                              <LabelWithError
                                hasStar
                                title={strings.photos.edit.form.keywords}
                                error={getErrorText(
                                  errors?.photoRows?.[index]?.keywords_translated,
                                  errors?.photoRows?.[index]?.keywords?.type,
                                )}
                              >
                                <StyledTextarea
                                  hasError={!!errors?.photoRows?.[index]?.keywords_translated}
                                  {...register(`photoRows.${index}.keywords_translated` as const, {
                                    required: true,
                                    maxLength: 300,
                                  })}
                                />
                              </LabelWithError>
                            </div>
                            {fieldsPhotos.length > 1 && (
                              <CommonButtonDefault
                                className="!px-0 mt-5"
                                type="button"
                                xs
                                transparent
                                onClick={() =>
                                  copyToAll('keywords_translated', `photoRows.${index}.keywords_translated`, index)
                                }
                              >
                                <DocumentDuplicateIcon width={18} height={18} />
                              </CommonButtonDefault>
                            )}
                          </div>
                        )}
                        <div className="sm:grid sm:grid-cols-3 space-y-4 sm:space-y-0 sm:space-x-3">
                          <div>
                            <StyledLabel>{strings.photos.edit.form.country}</StyledLabel>

                            <Controller
                              control={control}
                              name={`photoRows.${index}.country` as const}
                              rules={{ required: 'Pasirinkite šalį' }}
                              render={({ field }) => (
                                <Select
                                  className="w-50"
                                  options={countries}
                                  getOptionValue={(option: Country) => `${option.id}`}
                                  getOptionLabel={(option: Country) => `${option.name}`}
                                  placeholder={strings.photos.edit.form.placeholder.country}
                                  value={countries.find((opt) => opt.id === field.value)}
                                  onChange={(e) => {
                                    field.onChange(e?.id);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div>
                            <StyledLabel>{strings.photos.edit.form.language}</StyledLabel>
                            <Controller
                              control={control}
                              name={`photoRows.${index}.lang` as const}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select
                                  className="w-50"
                                  options={languages}
                                  getOptionValue={(option: Language) => `${option.id}`}
                                  getOptionLabel={(option: Language) => `${option.name}`}
                                  placeholder={strings.photos.edit.form.placeholder.country}
                                  value={languages.find((opt) => opt.id === field.value)}
                                  onChange={(e) => {
                                    field.onChange(e?.id);
                                    isTranslate
                                      ? setValue('photoRows.0.categories_translated', [{ id: null, parent_id: null }])
                                      : setValue('photoRows.0.categories', [{ id: null, parent_id: null }]);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div>
                            <StyledLabel>{strings.photos.edit.form.author}</StyledLabel>
                            {authors && (
                              <Controller
                                control={control}
                                name={`photoRows.${index}.author_id` as const}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <Select
                                    className="w-50"
                                    options={updatedAuthors(item.author_id, item.author_name, item.author_last_name)}
                                    getOptionValue={(option: Author) => `${option.id}`}
                                    getOptionLabel={(option: Author) =>
                                      `${option.first_name} ${option.last_name || ''}`
                                    }
                                    placeholder={strings.photos.edit.form.placeholder.author}
                                    value={updatedAuthors(item.author_id, item.author_name, item.author_last_name).find(
                                      (opt) => opt.id === field.value,
                                    )}
                                    onChange={(e) => {
                                      field.onChange(e?.id);
                                    }}
                                  />
                                )}
                              />
                            )}
                          </div>
                        </div>

                        <CategoriesFields
                          isTranslate={isTranslate}
                          fieldIndex={index}
                          categoriesList={findCategories(index)}
                          fieldsPhotosLength={fieldsPhotos.length}
                          {...{ control, watch, errors, getValues, setValue }}
                        />
                        {isTranslate && (
                          <CategoriesTranslatedFields
                            fieldIndex={index}
                            categoriesList={findCategories(index)}
                            fieldsPhotosLength={fieldsPhotos.length}
                            {...{ control, watch, errors, getValues, setValue }}
                          />
                        )}
                      </div>
                    </li>
                  ))}
              </ul>
            </section>
          </div>
          <div>
            <div className="mt-5 bg-white p-4 shadow ring-1 ring-gray-900/5 bottom-0 right-0 fixed z-[999] w-full sm:flex justify-end sm:space-x-3 space-y-4 sm:space-y-0">
              {(allowPublish || isTranslate) && (
                <div className="relative flex items-start py-1 mt-4">
                  <div className="flex h-6 items-center">
                    <Switch
                      checked={publishPhoto === 1}
                      onChange={() => {
                        setPublishPhoto(publishPhoto === 1 ? 0 : 1);
                      }}
                      className={classNames(
                        publishPhoto === 1 ? 'bg-indigo-600' : 'bg-gray-200',
                        // disabled ? 'opacity-50' : '',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          publishPhoto ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                        )}
                      />
                    </Switch>
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="is_published" className="font-medium text-gray-900">
                      {strings.photos.edit.form.publishPhoto}
                    </label>
                  </div>
                </div>
              )}
              {isTranslate ? (
                <CommonButtonDefault
                  className="w-full sm:w-auto"
                  type="submit"
                  primary
                  onClick={() => setNavigateToList(true)}
                >
                  {strings.photos.dropzone.buttons.saveAndExit}
                </CommonButtonDefault>
              ) : (
                <>
                  <CommonButtonDefault className="w-full sm:w-auto" type="submit" primary>
                    {strings.photos.dropzone.buttons.saveAndContinue}
                  </CommonButtonDefault>
                  <CommonButtonDefault
                    className="w-full sm:w-auto"
                    type="submit"
                    primary
                    onClick={() => setNavigateToList(true)}
                  >
                    {strings.photos.dropzone.buttons.saveAndExit}
                  </CommonButtonDefault>
                </>
              )}
            </div>
          </div>
        </div>
        {/* Error Messages */}
        {Object.keys(errors).length > 0 && (
          <div className="mt-4">
            <AlertPrimary type="danger" text={strings.photos.edit.form.errors.fillRequired} />
          </div>
        )}
      </form>
    </div>
  );
}

EditPhotoForm.defaultProps = {
  isTranslate: false,
};
