import React, { useState } from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '../../../hooks/use-auth';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import { strings } from '../../../localization/strings';
import { Pagination } from '../../../entities/pagination';
import { SimpleObject } from '../../../entities/simple-object';
import { fillFromParams } from '../../../services/crud/filter';
import * as photosService from '../../../services/api/photos';
import { Photo } from '../../../entities/photo';
import PhotosFilter from '../components/filter';
import FilterPhotos from '../../../entities/filter/photo';
import Heading1 from '../../../components/common/headings/heading1';
import PhotoList from '../components/photo-list';

function PhotosForeignPageComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const isLocalPhotosPage = location.pathname.includes('local');

  const initialState: FilterPhotos = {
    page: 0,
    sort: '-created_at',
    agency_id: 4,
    is_foreign: 'true',
  };

  const types = {
    isNumeric: ['page', 'language_id', 'agency_id'],
    isDate: ['valid_to_start', 'valid_to_end'],
    isArray: ['ids', 'author_id'],
  };

  const { token } = useAuth();
  const [params, setParams] = useState<FilterPhotos>(fillFromParams<FilterPhotos>(initialState, searchParams, types));

  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching } = useQuery<{ items: Photo[]; pagination: Pagination }, Error>({
    queryKey: ['foreignPhotos', params],
    queryFn: () => photosService.list(token, params),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
  });

  const setFilterData = (filterData: FilterPhotos) => {
    const q: SimpleObject = {};

    // Cleanup undefined and null values. We don't want them in URLs
    for (const [key, value] of Object.entries(filterData)) {
      if (typeof value !== 'undefined' && value !== null) {
        q[key] = value;
      }
    }

    setParams({ ...filterData, page: 0 });
    setSearchParams({ ...q, page: 0 } as SimpleObject);
    queryClient.invalidateQueries({ queryKey: ['foreignPhotos', params] });
  };

  const updatePage = (currentPage: number, newSort?: string) => {
    setParams({ ...params, page: currentPage, sort: newSort });
    setSearchParams({ ...params, page: currentPage, sort: newSort } as SimpleObject);
  };

  return (
    <div>
      <header className="mb-3">
        <div className="sm:flex sm:space-x-3 justify-between">
          <Heading1>{strings.navigation.photosforeignAgencies}</Heading1>
          {isLocalPhotosPage && (
            <div className="mt-3 sm:mt-0">
              <Link
                className="block text-center text-base rounded-lg py-2 px-4 font-medium bg-blue-800 text-white hover:bg-blue-900 no-underline"
                to="upload"
              >
                {strings.album.buttons.addPhotos}
              </Link>
            </div>
          )}
        </div>
      </header>

      <main className="w-full">
        {error && (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
            <AlertPrimary type="danger" text={error.message} />
          </div>
        )}

        <PhotosFilter isForeign onUpdate={setFilterData} initialState={initialState} filterState={params} />
        <PhotoList
          isForeign
          params={params}
          localPhotos={data}
          isLocalPhotosLoading={isLoading}
          isLocalPhotosFetching={isFetching}
          onUpdatePage={(page) => updatePage(page)}
        />
      </main>
    </div>
  );
}

export default PhotosForeignPageComponent;
