import React from 'react';
import {
  Controller,
  useFieldArray,
  Control,
  UseFormWatch,
  FieldErrorsImpl,
  DeepRequired,
  GlobalError,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import Select from 'react-select';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';

import StyledLabel from '../../../components/common/form/styled-label';
import { strings } from '../../../localization/strings';
import { Category } from '../../../entities/category';
import CommonButtonDefault from '../../../components/common/buttons/default';
import { EditPhotoFieldValues } from '../../../entities/form/edit-photo-form';

interface ComponentProps {
  fieldIndex: number;
  control: Control<EditPhotoFieldValues>;
  watch: UseFormWatch<EditPhotoFieldValues>;
  getValues: UseFormGetValues<EditPhotoFieldValues>;
  setValue: UseFormSetValue<EditPhotoFieldValues>;
  categoriesList: Category[];
  errors: Partial<FieldErrorsImpl<DeepRequired<EditPhotoFieldValues>>> & {
    root?: Record<string, GlobalError> & GlobalError;
  };
  fieldsPhotosLength: number;
  isTranslate?: boolean;
}

export default function CategoriesFields({
  fieldIndex,
  control,
  watch,
  categoriesList,
  errors,
  getValues,
  setValue,
  fieldsPhotosLength,
  isTranslate,
}: ComponentProps) {
  const {
    fields: categoriesArray,
    remove,
    append,
  } = useFieldArray({
    control,
    name: `photoRows.${fieldIndex}.categories` as `photoRows.0.categories`,
    keyName: 'fields_categories_id',
  });

  const findSubcategories = (index: number) => {
    const categories = categoriesList.find(
      (c) => c.id === watch(`photoRows.${fieldIndex}.categories.${index}.parent_id`),
    );

    return categories?.sub_category || [];
  };

  const copyCategories = () => {
    const selectedCategories = getValues(`photoRows.${fieldIndex}.categories`);

    for (let i = 0; i < fieldsPhotosLength; i += 1) {
      if (i > fieldIndex) {
        setValue(`photoRows.${i}.categories`, selectedCategories);
      }
    }
  };

  return (
    <div>
      {categoriesArray &&
        categoriesArray.map((item, index) => (
          <div
            key={item.fields_categories_id}
            className="sm:grid sm:grid-cols-5 pb-5 border-b border-b-gray-200 mb-4 space-y-4 sm:space-y-0 sm:space-x-3 items-center"
          >
            <div className="col-span-2">
              <StyledLabel>
                {strings.photos.edit.form.category}
                <span className="text-red-700">*</span>
              </StyledLabel>
              <Controller
                control={control}
                name={`photoRows.${fieldIndex}.categories.${index}.parent_id` as const}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    isDisabled={isTranslate}
                    id={`photoRows.${fieldIndex}.categories.${index}.parent_id`}
                    className="w-50"
                    styles={{
                      menu: (base) => ({ ...base, zIndex: 9999 }),
                      control: (proprovided) => ({
                        ...proprovided,
                        borderColor: errors.photoRows?.[fieldIndex]?.categories?.[index]?.parent_id && '#fca5a5',
                      }),
                    }}
                    options={categoriesList}
                    getOptionValue={(option: Category) => `${option.id}`}
                    getOptionLabel={(option: Category) => `${option.name}`}
                    placeholder={strings.photos.edit.form.placeholder.category}
                    value={categoriesList.find((opt) => opt.id === field.value)}
                    onChange={(e) => {
                      field.onChange(e?.id);
                      setValue(`photoRows.${fieldIndex}.categories.${index}.id` as const, null);
                    }}
                  />
                )}
              />

              {errors && errors.photoRows?.[fieldIndex]?.categories?.[index]?.parent_id && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {strings.form.error.categoryRequired}
                </p>
              )}
            </div>
            <div className="col-span-2">
              <StyledLabel>{strings.photos.edit.form.subcategory}</StyledLabel>

              {findSubcategories(index).length > 0 && (
                <Controller
                  key={`${watch(`photoRows.${fieldIndex}.categories.${index}.parent_id`)}`}
                  control={control}
                  name={`photoRows.${fieldIndex}.categories.${index}.id` as const}
                  render={({ field }) => (
                    <Select
                      isDisabled={isTranslate}
                      id={`photoRows.${fieldIndex}.categories.${index}.id`}
                      isClearable
                      className="w-50"
                      styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                      options={findSubcategories(index)}
                      getOptionValue={(option: Category) => `${option.id}`}
                      getOptionLabel={(option: Category) => `${option.name}`}
                      placeholder={strings.photos.edit.form.placeholder.subcategory}
                      defaultValue={findSubcategories(index).find((opt) => opt.id === field.value)}
                      onChange={(e) => {
                        field.onChange(e?.id);
                      }}
                    />
                  )}
                />
              )}

              {findSubcategories(index).length === 0 && <div>{strings.photos.edit.form.errors.noSubcategory}</div>}
            </div>
            {categoriesArray.length > 1 && !isTranslate && (
              <div className="pt-5">
                <CommonButtonDefault sm danger type="button" onClick={() => remove(index)}>
                  {strings.button.delete}
                </CommonButtonDefault>
              </div>
            )}
          </div>
        ))}
      {!isTranslate && (
        <div className="mt-4 flex items-center space-x-3">
          <CommonButtonDefault
            secondary
            sm
            type="button"
            onClick={() =>
              append({
                id: null, // stands for subcategory id
                parent_id: null, // stands for category id
              })
            }
          >
            {strings.photos.edit.form.addCategory}
          </CommonButtonDefault>
          {fieldsPhotosLength > 1 && (
            <CommonButtonDefault
              className="!inline-flex items-center space-x-1"
              secondary
              type="button"
              sm
              onClick={() => copyCategories()}
            >
              <DocumentDuplicateIcon width={18} height={18} /> <span>Kopijuoti kategorijas</span>
            </CommonButtonDefault>
          )}
        </div>
      )}
    </div>
  );
}

CategoriesFields.defaultProps = {
  isTranslate: false,
};
