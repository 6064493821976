import { Author } from '../../entities/author';
import { Administrator } from '../../entities/administrator';

export const updatedAuthors = (
  authors: Author[],
  user: Administrator | undefined,
  authorId?: number,
  authorName?: string,
  authorLastName?: string,
) => {
  let authorsUpdated = authors;

  if (authorId) {
    if (!authorsUpdated.find((obj) => obj.id === authorId)) {
      authorsUpdated = [
        {
          id: authorId,
          first_name: `${authorName} ${authorLastName}`,
        },
        ...authorsUpdated,
      ];
    }
  }

  if (user && !authorsUpdated.find((obj) => obj.id === user.id)) {
    authorsUpdated = [
      {
        id: user.id,
        first_name: user.name,
      },
      ...authorsUpdated,
    ];
  }

  return authorsUpdated;
};
