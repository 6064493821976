import React from 'react';

import CommonButtonDefault from './default';
import { strings } from '../../../localization/strings';

interface ComponentProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  secondary?: boolean;
  sm?: boolean;
}

function CommonButtonTooltip({ children, secondary, sm }: ComponentProps) {
  return (
    <div className="relative group inline-block">
      <CommonButtonDefault disabled secondary={secondary} sm={sm}>
        {children}
      </CommonButtonDefault>
      <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-max bg-black text-white text-sm px-3 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        {strings.photos.list.missingRequiredFields}
        <div className="absolute left-1/2 -translate-x-1/2 top-full border-4 border-transparent border-t-black" />
      </div>
    </div>
  );
}

CommonButtonTooltip.defaultProps = {
  secondary: false,
  sm: false,
};

export default CommonButtonTooltip;
