import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { PhotoIcon } from '@heroicons/react/20/solid';

import CommonButtonDefault from '../../../components/common/buttons/default';
import Badge from '../../../components/common/badge';
import { strings } from '../../../localization/strings';
import { fromTimestamp, fromTimestampFromSeconds } from '../../../services/formatter/date';
import { Photo, PhotoEdit } from '../../../entities/photo';
import { parameters } from '../../../constants/parameters';

interface ComponentProps {
  item: Photo;
  onUpdateCheckbox: (id: number, checked: boolean) => void;
  photoPreview: (showPhoto: boolean) => void;
  onDeleteItem?: (id: number) => void;
  onPublishPhoto?: (item: PhotoEdit) => void;
  onDownloadPhoto: (id: number) => void;
  isDownloading: boolean;
  isForeign?: boolean;
  ids?: number[];
}

function PhotoComponent({
  item,
  onUpdateCheckbox,
  photoPreview,
  onDeleteItem,
  onPublishPhoto,
  onDownloadPhoto,
  isDownloading,
  isForeign,
  ids,
}: ComponentProps) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const onDisplayenu = (show: boolean) => {
    setShowMenu(show);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div key={item.id} className="relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white">
      <div className="relative">
        <CommonButtonDefault
          transparent
          className="no-underline !py-0 !px-0 w-full text-center h-40 2xl:h-42 !bg-gray-100"
          onClick={() => photoPreview(true)}
        >
          {item?.preview_photo ? (
            <img
              alt={item.title}
              src={`${parameters.websiteUrl}/preview/${item?.preview_photo}`}
              className="object-cover object-center mx-auto"
            />
          ) : (
            <span className="h-full flex items-center">
              <PhotoIcon className="text-gray-300 m-auto" width={60} height={60} />
            </span>
          )}
        </CommonButtonDefault>

        <div className="absolute z-10 top-1 left-1">
          <input
            className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            id={`photo-${item.id}`}
            name={`photo-${item.id}`}
            type="checkbox"
            value={item.id}
            checked={ids?.includes(item.id)}
            onChange={(e) => {
              onUpdateCheckbox(item.id, e.target.checked);
            }}
          />
        </div>

        <div className="absolute z-60 top-1 right-1">
          <CommonButtonDefault secondary className="!px-2 !py-1.5" onClick={() => onDisplayenu(!showMenu)}>
            <Cog6ToothIcon width={20} height={20} />
          </CommonButtonDefault>
          {showMenu && (
            <div
              ref={menuRef}
              className="absolute right-1 !text-left text-sm flex flex-col space-y-2 bg-white py-2 px-3 z-50 ring-1 ring-inset ring-gray-300 rounded-b-md rounded-ss-md"
            >
              {!isForeign && (
                <Link
                  className="text-gray-900 no-underline hover:text-blue-500"
                  to={`/photos/local/edit?ids=${item.id}`}
                >
                  {strings.button.edit}
                </Link>
              )}
              <CommonButtonDefault
                sm
                menulink
                onClick={() => {
                  photoPreview(true);
                  onDisplayenu(!showMenu);
                }}
              >
                {strings.button.preview}
              </CommonButtonDefault>
              {((item.keywords !== null && item.categories.length > 0 && !item.title) || isForeign) && (
                <Link
                  className="text-gray-900 no-underline hover:text-blue-500"
                  to={`/photos/${isForeign ? 'foreign' : 'local'}/translate?ids=${item.id}`}
                >
                  {strings.button.translate}
                </Link>
              )}
              {item.preview_photo && (
                <CommonButtonDefault
                  disabled={isDownloading}
                  sm
                  menulink
                  onClick={() => {
                    onDownloadPhoto(item.id);
                  }}
                >
                  {isDownloading ? strings.button.downloading : strings.button.download}
                </CommonButtonDefault>
              )}
              {!item.published_at && onPublishPhoto && !isForeign && (
                <CommonButtonDefault sm menulink onClick={() => onPublishPhoto(item)}>
                  {strings.button.publish}
                </CommonButtonDefault>
              )}
              {!isForeign && onDeleteItem && (
                <CommonButtonDefault sm menulink onClick={() => onDeleteItem(item.id)}>
                  {strings.button.delete}
                </CommonButtonDefault>
              )}
            </div>
          )}
        </div>
        {!item.published_at && !isForeign && (
          <span className="absolute bottom-1 left-1 z-10">
            <Badge status="warning" text="Ruošiama" />
          </span>
        )}
        {(!item.keywords || item.categories.length === 0) && (
          <span className="absolute bottom-1 left-1 z-10">
            <Badge status="danger" text="Trūksta informacijos" />
          </span>
        )}
      </div>

      <CommonButtonDefault
        transparent
        className="text-gray-900 no-underline hover:text-indigo-600 text-left w-full !p-3"
        onClick={() => onUpdateCheckbox(item.id, !(ids && ids?.includes(item.id)))}
      >
        <h3 className="text-sm font-medium">{item.title}</h3>

        <div>
          <div className="text-xs text-gray-500">
            <div>ID: {item.id}</div>
            {strings.album.created} {fromTimestamp(item.created_at, true)}
          </div>
          {!isForeign && (
            <div className="text-xs text-gray-500">
              {strings.album.published} {item.published_at ? fromTimestampFromSeconds(item.published_at, true) : '-'}
            </div>
          )}
        </div>
      </CommonButtonDefault>
    </div>
  );
}

PhotoComponent.defaultProps = {
  onPublishPhoto: () => null,
  onDeleteItem: () => null,
  isForeign: false,
  ids: null,
};

export default PhotoComponent;
