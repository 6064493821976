import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { strings } from '../../localization/strings';
import Heading1 from '../../components/common/headings/heading1';
import UploadPhotosForm from './components/upload-form';
import { Author } from '../../entities/author';
import * as photosService from '../../services/api/photos';
import { CompanyAdminStructure } from '../../entities/company-admin-structure';
import { companyAdminStructure } from '../../services/api/categories';
import { useAuth } from '../../hooks/use-auth';
import AlertPrimary from '../../components/common/alerts/alert-primary';

export default function AlbumsUploadPageComponent() {
  const { token } = useAuth();

  const { data: authors, error: authorsError } = useQuery<Author[], Error>({
    queryKey: ['photoAuthors'],
    queryFn: () => photosService.authors(token),
    refetchOnWindowFocus: false,
  });

  const { data: languages, error: languagesError } = useQuery<CompanyAdminStructure>({
    queryKey: ['languages'],
    queryFn: () => companyAdminStructure(token),
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {authorsError && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
          <AlertPrimary type="danger" text={authorsError.message} />
        </div>
      )}

      {languagesError && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
          <AlertPrimary type="danger" text={(languagesError as Error).message} />
        </div>
      )}

      <header className="mb-3">
        <div className="flex space-x-3 justify-between">
          <Heading1>{strings.album.header.upload}</Heading1>
        </div>
      </header>
      <div className="max-w-7xl">
        {authors && languages && <UploadPhotosForm authors={authors} languages={languages.languages} />}
      </div>
    </>
  );
}
