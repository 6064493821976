import React from 'react';
import classNames from 'classnames';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { NavLink, useNavigate } from 'react-router-dom';

import IMAGES from '../../assets/images';
import { strings } from '../../localization/strings';
import { parameters } from '../../constants/parameters';
import { useAuth } from '../../hooks/use-auth';

function SidebarComponent() {
  const { user, signout } = useAuth();
  const navigate = useNavigate();

  const onSignoutClick = () => {
    signout();
    navigate('/');
  };
  /*
    // Oranžinė
    // Priskirta Man
      // čia kiekvienas viduje turi dar kategorijas
      // Naujienos
      // Nuotraukos
      // Pranešimai spaudai

    // Mėlyna
    // Ruošiamos naujienos
      // kategorijos viduje
    // Užsienio agentūrų naujienos
      // AFP
      // DPA
      // UKRINFORM
      // PAP (EN)
      // PAP (LT)
    // Atidėtas publikavimas
      // kategorijos viduje
    // Publikuotos naujienos
      // kategorijos viduje

    // Žalia
    // Ruošiamos nuotraukos
      // kategorijos viduje
    // Užsienio agentūrų nuotraukos
      // EPA?
    // Publikuotos nuotraukos
      // kategorijos viduje

    // Geltona
    // Ruošiami pranešimai spaudai
      // kategorijos viduje
    // Atidėtas publikavimas pranešimai spaudai
      // kategorijos viduje
    // Publikuoti pranešimai spaudai
      // kategorijos viduje

    // Balta
    // Numatomi įvykiai
    // Konferencijos
      // Ruošiamos konferencijos
      // Publikuotos konferencijos
      // Atidėtas publikavimas
    // Šiukšliadėžė
      // Naujienos
        // kategorijos viduje
      // Nuotraukos
        // kategorijos viduje
    // TVS
      // Baneriai
      // Pagrindinis puslapis
      // Kiti puslapiai
      // Skaidrės
      // Paslaugos
      // ELTA
      // Kontaktai
      // Temos
      // Ženkliukai
      // Pranešimų spaudai prenumeratoriai
      // Pranešimų spaudai prenumeratorių grupės
      // Vertimai
    // ADMIN
      // Įmonės
      // Vartotojai
      // Demo
      // Darbuotojai
      // Skyriai
      // Logs
  */

  const items = [
    { title: strings.navigation.returnToMainAdmin, href: `${parameters.websiteUrl}/lt/backend`, external: true },
    { title: strings.navigation.companies, href: '/companies' },
    {
      title: strings.navigation.photos,
      group: [
        { title: strings.navigation.lt, href: '/photos/local' },
        { title: strings.navigation.foreign, href: '/photos/foreign' },
        { title: strings.navigation.photoAlbums, href: '/albums' },
      ],
    },
  ];

  return (
    <div className="flex grow flex-col overflow-y-auto bg-white border-r border-gray-200 pb-4">
      <div className="flex h-[60px] shrink-0 items-center">
        <img className="h-[21px] ml-[40px] w-auto" src={IMAGES.LOGOTYPES.PRIMARY} alt="ELTA" />
      </div>
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul className="mx-0">
              {items.map((item) => (
                <React.Fragment key={item.title}>
                  {item.href && (
                    <li key={item.title}>
                      {item.external ? (
                        <a
                          className="group flex gap-x-3 p-2 text-sm no-underline border-l-transparent border-l-[5px] leading-tight text-gray-700 hover:text-indigo-600 hover:bg-gray-50"
                          href={item.href}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.title}
                        </a>
                      ) : (
                        <NavLink
                          reloadDocument
                          to={item.href}
                          className={({ isActive }: { isActive: boolean }) =>
                            `group flex gap-x-3 p-2 pl-4 text-sm no-underline border-b-gray-100 border-l-[5px] border-gray-200 leading-tight ${
                              isActive
                                ? 'border-l-indigo-600 bg-gray-50 text-indigo-600'
                                : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50'
                            }`
                          }
                        >
                          {item.title}
                        </NavLink>
                      )}
                    </li>
                  )}
                  {item.group && (
                    <li key={item.title}>
                      <div className="p-2 mt-3 font-medium text-md text-gray-700">{item.title}</div>
                      {item?.group?.map((subItem) => (
                        <NavLink
                          key={subItem.title}
                          reloadDocument
                          to={subItem.href}
                          className={({ isActive }: { isActive: boolean }) =>
                            `group flex gap-x-3 p-2 pl-4 text-sm no-underline border-l-[5px] border-gray-200 leading-tight ${
                              isActive
                                ? 'border-l-indigo-600 bg-gray-50 text-indigo-600'
                                : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50'
                            }`
                          }
                        >
                          {subItem.title}
                        </NavLink>
                      ))}
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </li>
          <li className="mt-auto px-3">
            <Menu as="div" className="relative">
              <Menu.Button className="flex items-center">
                <span className="sr-only">Open user menu</span>
                <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
                  <span className="text-xs font-medium leading-none text-white">
                    {user?.email && user?.email.substring(0, 1).toUpperCase()}
                  </span>
                </span>
                <span className="hidden lg:flex lg:items-center">
                  <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                    {user?.email}
                  </span>
                  <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Menu.Button>
              <Transition
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 bottom-8 z-10 mt-2.5 w-32 origin-bottom-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <Menu.Item>
                    <button
                      type="button"
                      onClick={onSignoutClick}
                      className={classNames('block px-3 py-1 text-sm leading-6 text-gray-900')}
                    >
                      {strings.navigation.signOut}
                    </button>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default SidebarComponent;
