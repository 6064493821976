import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { strings } from '../../../localization/strings';
import * as photosService from '../../../services/api/photos';
import { useAuth } from '../../../hooks/use-auth';
import { Photo } from '../../../entities/photo';
import { fillFromParams } from '../../../services/crud/filter';
import { Pagination } from '../../../entities/pagination';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import { Author } from '../../../entities/author';
import EditPhotoForm from '../components/edit-form';
import CommonAnimatedLoader from '../../../components/common/animated/loader';
import { CompanyAdminStructure } from '../../../entities/company-admin-structure';
import { companyAdminStructure } from '../../../services/api/categories';
import Heading1 from '../../../components/common/headings/heading1';

interface ComponentProps {
  isForeign?: boolean;
}

function PhotosTranslatePageComponent({ isForeign }: ComponentProps) {
  const [searchParams] = useSearchParams();

  const photosIds = searchParams.getAll('ids');

  let initialState: { ids: string[]; is_foreign?: string } = {
    ids: photosIds,
  };

  if (isForeign) {
    initialState = { ...initialState, is_foreign: 'true' };
  }

  const types = {
    isArray: ['ids'],
  };
  const { token } = useAuth();

  const { data, isError, error, isLoading } = useQuery<{ items: Photo[]; pagination: Pagination }, Error>({
    queryKey: ['photosToTranslate', searchParams],
    queryFn: async () => {
      const items = await photosService.list(token, {
        ...fillFromParams(initialState, searchParams, types),
        limit: 1000,
      });

      if (items && items.items.length > 0) {
        for (const item of items.items) {
          if (item.categories && item.categories.length === 0) {
            item.categories = [{ id: null, parent_id: null }];
          } else {
            for (const categoryRow of item.categories) {
              if (categoryRow && categoryRow.parent_id === null && categoryRow.id) {
                categoryRow.parent_id = categoryRow.id;
                categoryRow.id = null;
              }
            }
          }
          item.categories_translated = [{ id: null, parent_id: null }];
        }
      }

      return items;
    },
    cacheTime: 0, // Disable cache time
    staleTime: 0,
  });

  const { data: authors, error: authorsError } = useQuery<Author[], Error>({
    queryKey: ['photoAuthors'],
    queryFn: () => photosService.authors(token),
  });

  const { data: languages } = useQuery<CompanyAdminStructure>({
    queryKey: ['languages'],
    queryFn: () => companyAdminStructure(token),
  });

  return (
    <div className="max-w-7xl">
      <header className="mb-5">
        <div className="flex space-x-3 justify-between">
          <Heading1>{strings.photos.header.translate}</Heading1>
        </div>
      </header>
      {isError && error && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
          <AlertPrimary type="danger" text={error.message} />
        </div>
      )}
      {authorsError && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
          <AlertPrimary type="danger" text={authorsError.message} />
        </div>
      )}
      {isLoading && <CommonAnimatedLoader />}
      {data && data.items && authors && languages && (
        <EditPhotoForm isTranslate items={data.items} authors={authors} languages={languages.languages} />
      )}
    </div>
  );
}

PhotosTranslatePageComponent.defaultProps = {
  isForeign: false,
};

export default PhotosTranslatePageComponent;
