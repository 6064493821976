import React, { useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { useAuth } from '../../hooks/use-auth';
import { strings } from '../../localization/strings';
import * as albumsService from '../../services/api/albums';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import { Album } from '../../entities/album';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import { Photo } from '../../entities/photo';
import { Pagination } from '../../entities/pagination';
import * as photosService from '../../services/api/photos';
import PhotoList from '../photos/components/photo-list';
import FilterPhotos from '../../entities/filter/photo';
import { fillFromParams } from '../../services/crud/filter';
import { SimpleObject } from '../../entities/simple-object';
import Heading1 from '../../components/common/headings/heading1';

function AlbumsViewPageComponent() {
  const { id } = useParams();

  const { token } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const types = {
    isNumeric: ['page', 'is_published', 'language_id', 'album_id'],
    isDate: ['valid_to_start', 'valid_to_end'],
    isArray: ['is_published', 'ids', 'author_id'],
  };

  const initialState: FilterPhotos = {
    page: 0,
    sort: '-created_at',
    is_published: [1, 0],
    language_id: 0,
  };

  const { data: album, error: albumError } = useQuery<Album, Error>({
    queryKey: ['album', id],
    queryFn: () => albumsService.one(token, Number(id)),
    refetchOnWindowFocus: false,
  });

  const [params, setParams] = useState<FilterPhotos>(fillFromParams<FilterPhotos>(initialState, searchParams, types));

  const {
    data: photos,
    error,
    isLoading: isPhotosLoading,
    isFetching: isPhotosFetching,
  } = useQuery<{ items: Photo[]; pagination: Pagination }, Error>({
    queryKey: ['albumPhotos', params],
    queryFn: () => photosService.list(token, { album_id: id }),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
  });

  const updatePage = (currentPage: number) => {
    setParams({ ...params, page: currentPage });
    setSearchParams({ ...params, page: currentPage } as SimpleObject);
  };

  return (
    <div>
      <header className="mb-3">
        <div className="sm:flex sm:space-x-3 justify-between">
          <Heading1>
            {strings.album.header.title} {album?.title}
          </Heading1>
          <div className="mt-4 sm:mt-0">
            <Link
              className="bg-blue-800 text-center text-white hover:bg-blue-900 block text-base rounded py-2 px-4 font-medium no-underline"
              to={`/albums/upload/${id}`}
            >
              {strings.album.buttons.addPhotos}
            </Link>
          </div>
        </div>
      </header>

      <div>
        {isPhotosLoading || isPhotosFetching ? (
          <CommonAnimatedLoader />
        ) : (
          <div className="py-5 lg:py-7">
            <h2 className="sr-only">{strings.album.header.title}</h2>
            {albumError && (
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
                <AlertPrimary type="danger" text={albumError.message} />
              </div>
            )}

            {error && (
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
                <AlertPrimary type="danger" text={error.message} />
              </div>
            )}

            <PhotoList
              params={params}
              localPhotos={photos}
              isLocalPhotosLoading={isPhotosLoading}
              isLocalPhotosFetching={isPhotosFetching}
              onUpdatePage={(page) => updatePage(page)}
              albumId={id}
            />
            {photos?.items.length === 0 && (
              <div>
                <div className="w-[200px]">
                  <Link
                    className="bg-blue-800 text-white text-center hover:bg-blue-900 block text-base rounded py-2 px-4 font-medium no-underline"
                    to={`/albums/upload/${id}`}
                  >
                    {strings.album.buttons.addPhotos}
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AlbumsViewPageComponent;
